import {Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TasksService} from '../../../services/api/tareas/tasks.service';
import {TaskModel} from '../../../models';
import {AccionesService} from '../../../services/api/tareas/acciones/acciones.service';
import {GLOBAL} from '../../../services/global';
import Swal from 'sweetalert2';
import {StorageService} from '../../../services/storage.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {Observable} from 'rxjs/Observable';


export interface DialogData {
  action: string;
  tarea: TaskModel;
}

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.css'],
  providers: [TasksService]
})
export class TaskListComponent implements OnInit, OnDestroy {

  private countModalOpen = 0;
  public tarea: TaskModel;
  private taskSelectedSubscription: Subscription;
  private actionReceivedSubscription: Subscription;

  @Input() tasks: TaskModel[];
  @Input() validStatusArray: number[] = [];
  @Input() taskSelected: Observable<TaskModel>;
  @Input() onActionReceived: Observable<string>;
  @Output() pullRefreshStatusEvent = new EventEmitter<boolean>();
  @Output() openQRScanEvent = new EventEmitter<TaskModel>();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _tasksService: TasksService,
    private storageService: StorageService,
    public accionesService: AccionesService,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    if (this.taskSelected !== undefined) {
      this.taskSelectedSubscription = this.taskSelected.subscribe((task) => {
        if (this.isValidStatus(task.id_estado)) {
          console.log('taskSelectedSubscription', task);
          this.tarea = task;
          this.tarea.id = task.id;
          this.processAddActions();
        }
      });
    }

    if (this.onActionReceived !== undefined) {
      this.actionReceivedSubscription = this.onActionReceived.subscribe((action) => {
        console.log(action);
        if (action === GLOBAL.TASK_ACTION.CREATE_TASK) {
          this.tarea = new TaskModel();
          this.processAddActions('create_task');
        } else if (action === GLOBAL.TASK_ACTION.CREATE_INCIDENT) {
          this.tarea = new TaskModel();
          this.processAddActions('create_incident');
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.taskSelectedSubscription !== undefined) {
      this.taskSelectedSubscription.unsubscribe();
    }

    if (this.actionReceivedSubscription !== undefined) {
      this.actionReceivedSubscription.unsubscribe();
    }
  }

  isValidStatus(status_id): boolean {
    return this.validStatusArray.find(e => e === status_id) !== undefined;
  }

  togglePullRefreshStatus(): void {
    this.pullRefreshStatusEvent.emit(this.countModalOpen === 0);
  }

  requestQRScanner(task): void {
    console.log('requestQRScanner');
    this.openQRScanEvent.emit(task);
  }

  showModal(tarea): void {
    // Guardar en una variable global
    this.tarea = tarea;
    console.log(tarea);
    this.countModalOpen++;
    this.togglePullRefreshStatus();
    const dialogRef = this.dialog.open(DialogTaskActionsComponent, {
      data: {action: '', tarea: this.tarea}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('showModal - The dialog was closed', result);
      this.countModalOpen--;
      this.togglePullRefreshStatus();
      if (result !== undefined) {
        this.onClickMenu(result.action);
      }
    });
  }

  private processAddActions(type = 'add_actions'): void {
    // enviar a el componente acciones pasando los parametros
    this.accionesService.destroy();
    this.accionesService.calledFrom = 'task-list';
    this.accionesService.idTarea = this.tarea.id;
    this.accionesService.tarea = this.tarea;

    let tipoCheck = this.tarea.tipo_check?.tipo_accion;
    if (type === 'create_task') {
      tipoCheck = 'NORMAL';
    } else if (type === 'create_incident') {
      tipoCheck = 'INCIDENTE';
    }

    this._router.navigate(['/agregar-accion', this.tarea.id, tipoCheck]);
  }

  openMap(task): void {
    window.open('https://maps.google.com/maps?saddr=' + task.destino_latitud + ',' + task.destino_longitud +
      '&daddr=' + task.destino_latitud + ',' + task.destino_longitud, '_blank');
  }

  onClickMenu(opcion): void {
    if (opcion === 'agregar-accion') {
      if (this.tarea.articulo_unidad != null && this.tarea.movimiento_articulo != null) {
        this.requestQRScanner(this.tarea);
      } else {
        this.processAddActions();
      }

    } else if (opcion === 'informacion') {
      this.countModalOpen++;
      this.togglePullRefreshStatus();
      const dialogRef = this.dialog.open(DialogTaskObservationsComponent, {
        data: this.tarea
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        this.countModalOpen--;
        this.togglePullRefreshStatus();
      });

    } else if (opcion === 'historial') {
      this.accionesService.calledFrom = 'task-list';
      this.accionesService.tarea = this.tarea;
      this._router.navigate(['/historic-task']);

    } else if (opcion === 'terminar') {
      this._tasksService.updateTaskStatus(this.tarea.id, GLOBAL.TASK_STATUS_CLOSE)
        .then(
          result => { // Success
            Swal.fire({
              title: 'TaskTrack',
              text: 'Proceso realizado con éxito',
              icon: 'success',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: GLOBAL.color_accent
            }).then((alertResult) => {
              this.tarea.id_estado = Number(GLOBAL.TASK_STATUS_CLOSE);
              this.tasks = this._tasksService.updateTaskOnList(this.tasks, this.tarea);
              this.storageService.setObject(GLOBAL.KEY_LAST_TASK_LIST, this.tasks);
            });

          },
          result => { // Error
            console.log('agregar-accion reportAction result error ', result);
            Swal.fire({
              title: 'TaskTrack',
              text: GLOBAL.text_default_error_msg,
              icon: 'error',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: GLOBAL.color_accent
            }).then((alertResult) => {

            });
          }
        );
    } else if (opcion === 'cancelar') {
      this._tasksService.updateTaskStatus(this.tarea.id, GLOBAL.TASK_STATUS_CANCEL)
        .then(
          result => { // Success
            Swal.fire({
              title: 'TaskTrack',
              text: 'Proceso realizado con éxito',
              icon: 'success',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: GLOBAL.color_accent
            }).then((alertResult) => {
              this.tarea.id_estado = Number(GLOBAL.TASK_STATUS_CANCEL);
              this.tasks = this._tasksService.updateTaskOnList(this.tasks, this.tarea);
              this.storageService.setObject(GLOBAL.KEY_LAST_TASK_LIST, this.tasks);
            });

          },
          result => { // Error
            console.log('agregar-accion reportAction result error ', result);
            Swal.fire({
              title: 'TaskTrack',
              text: GLOBAL.text_default_error_msg,
              icon: 'error',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: GLOBAL.color_accent
            }).then((alertResult) => {

            });
          }
        );
    }
  }
}


@Component({
  selector: 'app-dialog-task-actions',
  templateUrl: 'dialog-task-actions.html',
})
export class DialogTaskActionsComponent {
  public tarea: TaskModel;

  constructor(
    public dialogRef: MatDialogRef<DialogTaskActionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.tarea = this.data.tarea;
  }

  onClickMenu(action): void {
    this.data.action = action;
    this.dialogRef.close(this.data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'app-dialog-task-observations',
  templateUrl: 'dialog-task-observations.html',
})
export class DialogTaskObservationsComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogTaskObservationsComponent>,
    @Inject(MAT_DIALOG_DATA) public tarea: TaskModel) {
  }

  openMap(): void {
    window.open('https://maps.google.com/maps?saddr=' + this.tarea.destino_latitud + ',' + this.tarea.destino_longitud +
      '&daddr=' + this.tarea.destino_latitud + ',' + this.tarea.destino_longitud, '_blank');
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
