export class TaskModel {
  id: number;
  tipo_check_nombre: string;
  cuenta_nombre: string;
  descripcion: string;
  distancia: string;
  cod_articulo: string;
  fecha_programada: string;
  inicio: string;
  ultima_actualizacion: string;
  cuenta: any;
  tipo_check: any;
  empresa_cuenta_local: any;
  articulo_unidad: any;
  movimiento_articulo: any;
  id_estado: number;
  id_tipo_check: number;
  tipo_accion: string;
  fecha_desde: string;
  fecha_hasta: string;
  duracion: string;
  acciones_reportadas: ReportedTaskModel[];
  destino_latitud: number;
  destino_longitud: number;
  actualizado: string;

  constructor() {
    this.id = 0;
    this.acciones_reportadas =  [];
    this.actualizado =  '';
    this.articulo_unidad =  null;
    this.cod_articulo =  '';
    this.cuenta =  null;
    this.cuenta_nombre =  '';
    this.descripcion =  '';
    this.destino_latitud =  0;
    this.destino_longitud =  0;
    this.distancia =  '';
    this.duracion =  '';
    this.empresa_cuenta_local =  null;
    this.fecha_desde =  '';
    this.fecha_hasta =  '';
    this.fecha_programada =  '';
    this.id_estado =  0;
    this.id_tipo_check =  0;
    this.inicio =  '';
    this.movimiento_articulo =  null;
    this.tipo_check =  null;
    this.tipo_check_nombre =  '';
    this.ultima_actualizacion =  '';
  }
}

export class ReportedTaskModel {
  id: number;
  accion_descripcion: string;
  observacion: string;
  fecha_hora: string;
  fecha?: string;
  usuario_latitud: number;
  usuario_longitud: number;
  usuario_accuracy: number;
  latitud: number;
  longitud: number;
  accuracy: number;
  checks_acciones_stocks?: any;
  stock?: any;
}
