<mat-toolbar color="primary">
  <button mat-icon-button class="example-icon" aria-label="back" *ngIf="showBack" (click)="goToBack()">
    <mat-icon class="back-bottom-brand" aria-hidden="false" aria-label="back">arrow_back_ios</mat-icon>
  </button>
  <span><img src="https://tasktrack.appsolutions.top/images/logo-tasktrack-blanco.png" height="35" class="d-inline-block align-top" alt="tasktrack" loading="lazy"></span>
  <span class="icon-spacer"></span>
  <button *ngIf="showUpdateTaskList" mat-icon-button aria-label="update list" (click)="onMenuItemClicked(MENU.UPDATE_TASK_LIST)">
    <mat-icon>refresh</mat-icon>
  </button>
  <button *ngIf="showQRScanner" mat-icon-button aria-label="qr" (click)="onMenuItemClicked(MENU.SCAN_QR)">
    <mat-icon>qr_code_scanner</mat-icon>
  </button>
  <button mat-icon-button aria-label="menu" [matMenuTriggerFor]="generaMenu">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #generaMenu="matMenu" >
    <button *ngIf="showCreateTask" mat-menu-item (click)="onMenuItemClicked(MENU.CREATE_TASK)">
      <mat-icon>add_circle_outline</mat-icon>
      <span>Crear Tarea</span>
    </button>
    <button *ngIf="showCreateIncident" mat-menu-item (click)="onMenuItemClicked(MENU.CREATE_INCIDENT)">
      <mat-icon>report_gmailerrorred</mat-icon>
      <span>Reportar Incidente</span>
    </button>
    <button *ngIf="showRequestLocation" mat-menu-item (click)="onMenuItemClicked(MENU.ENABLE_LOCATION)">
      <mat-icon>share_location</mat-icon>
      <span>Habilitar GPS</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Salir</span>
    </button>
  </mat-menu>
</mat-toolbar>
<div style="height: 56px"></div>
