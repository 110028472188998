<app-nav-bar [showBack]="true" ></app-nav-bar>
<div style="text-align:center; background-color: black; height: 100vh; position: fixed; top: 0" *ngIf="showWebcam">

  <div class="camara">
    <webcam [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
            *ngIf="showWebcam"
            [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
            [videoOptions]="videoOptions"
            [imageQuality]="1"
            (cameraSwitched)="cameraWasSwitched($event)"
            (initError)="handleInitError($event)">
    </webcam>
    <div class="row option-control">
      <div class="col-6" style="text-align: center">
        <button class="btn btn-secondary btn-110" (click)="goToBack();">Cancelar</button>
      </div>
      <div class="col-6" style="text-align: center">
        <button class="btn btn-accent btn-110" (click)="triggerSnapshot();">Tomar foto</button>
      </div>
    </div>
  </div>
</div>

<div style="text-align:center; background-color: black; height: 100vh; position: fixed; top: 0" *ngIf="webcamImage">
  <img style="height: 100vh; width: 100vw" [src]="webcamImage.imageAsDataUrl"/>

  <div class="row option-control">
    <div class="col-4" style="text-align: center">
      <button class="btn btn-secondary btn-110" (click)="goToBack();">Cancelar</button>
    </div>
    <div class="col-4">
      <button class="btn btn-110 btn-primary" (click)="showWebcam=true; webcamImage=null; triggerSnapshot();">Tomar otra</button>
    </div>
    <div class="col-4">
      <button class="btn btn-110 btn-accent" (click)="sendPhoto();">Reportar</button>
    </div>
  </div>
</div>

<h4 *ngIf="errors.length > 0">Messages:</h4>
<ul *ngFor="let error of errors">
  <li>{{error | json}}</li>
</ul>


