<h1 mat-dialog-title>Informaciones</h1>
<mat-dialog-content>
  <div class="row" style="text-align: justify;">
    <span style="font-weight: bolder">Identificador:&nbsp;</span>{{tarea?.id}}
  </div>
  <div class="row" style="text-align: justify;" *ngIf="tarea?.empresa_cuenta_local?.empresa_cuenta_local_telefono">
    <span style="font-weight: bolder">Dirección:&nbsp;</span>{{tarea?.empresa_cuenta_local?.direccion}}
  </div>
  <div class="row" style="text-align: justify;" *ngIf="tarea?.empresa_cuenta_local?.empresa_cuenta_local_telefono ||
          tarea?.empresa_cuenta_local?.cuenta?.empresa_cuenta_telefono">
    <span style="font-weight: bolder">Teléfono:&nbsp;</span>{{ tarea?.empresa_cuenta_local?.empresa_cuenta_local_telefono ?
    tarea?.empresa_cuenta_local?.empresa_cuenta_local_telefono : tarea?.empresa_cuenta_local?.cuenta?.empresa_cuenta_telefono }}
  </div>
  <div *ngIf="tarea?.descripcion" class="row" style="text-align: justify;">
    <span style="font-weight: bolder">Observación:&nbsp;</span>{{tarea?.descripcion}}
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button  (click)="openMap()">Ver en mapa</button>
  <button mat-button color="accent" (click)="onNoClick()">Salir</button>
</mat-dialog-actions>
