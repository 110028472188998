import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef, AfterViewInit
} from '@angular/core';


import videojs from 'video.js';
import * as adapter from 'webrtc-adapter/out/adapter_no_global.js';
import * as RecordRTC from 'recordrtc';

// Required imports
import * as WaveSurfer from 'wavesurfer.js';
import * as MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';
import {AgregarAccionService} from '../../../../services/api/tareas/agregar-accion/agregar-accion.service';
import {ActivatedRoute, Router, Params} from '@angular/router';
import {LocationService} from '../../../../services/api/tareas/geolocalizacion/location.service';
import {StorageService} from '../../../../services/storage.service';
import {AccionesService} from '../../../../services/api/tareas/acciones/acciones.service';
import {formatDate, Location} from '@angular/common';

WaveSurfer.microphone = MicrophonePlugin;

// Register videojs-wavesurfer plugin
import * as Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer.js';


// register videojs-record plugin with this import
import * as Record from 'videojs-record/dist/videojs.record.js';

@Component({
  selector: 'app-videojs-record',
  templateUrl: './videojs-record.component.html',
  styleUrls: ['./videojs-record.component.css']
})
export class VideojsRecordComponent implements OnInit, OnDestroy, AfterViewInit {

  // reference to the element itself: used to access events and methods
  // tslint:disable-next-line:variable-name
  private _elementRef: ElementRef;

  // index to create unique ID for component
  idx = 'clip1';

  public config: any;
  public player: any;
  public wavesurfer: any;
  public webcamAV: any;
  public plugin: any;
  public isRecord: boolean;
  public isStop: boolean;
  public isPlay: boolean;
  public isPlayPause: boolean;

  public devices: any[] = [];
  public selectedDeviceIndex = 0;


  // constructor initializes our declared vars
  constructor(
    elementRef: ElementRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public accionService: AgregarAccionService,
    public accionesService: AccionesService,
    public locationService: LocationService,
    private storage: StorageService,
    private location: Location
  ) {
    this.player = false;

    // save reference to plugin (so it initializes)
    this.plugin = Record;

    // video.js configuration
    this.config = {
      controls: true,
      autoplay: false,
      preload: 'auto',
      fluid: true,
      loop: false,
      bigPlayButton: false,
      controlBar: {
        volumePanel: false,
        fullscreenToggle: false,
        pictureInPictureToggle: false
      },
      plugins: {
        record: {
          audio: true,
          video: true,
          debug: false,
          videoMimeType: 'video/webm',
          audioMimeType: 'audio/webm'
        }
      }
    };
  }

  ngOnInit(): void {
  }

  // use ngOnDestroy to detach event handlers and remove the player
  ngOnDestroy(): void {
    if (this.player) {
      console.log('Player dispose');
      this.player.dispose();
      this.player = false;
    }
  }

  ngAfterViewInit(): void {
    const videoPlayer = document.getElementById('video_' + this.idx);
    // setup the player via the unique element ID
    this.player = videojs(videoPlayer, this.config, () => {

      // Activar la camara al iniciar el componente.
      this.player.record().getDevice();

      // print version information at startup
      const msg = 'Using video.js ' + videojs.VERSION +
        ' with videojs-record ' + videojs.getPluginVersion('record') +
        ' and recordrtc ' + RecordRTC.version;
      videojs.log(msg);
    });

    // device is ready
    this.player.on('deviceReady', () => {
      console.log('device is ready!');
    });

    // user clicked the record button and started recording
    this.player.on('startRecord', () => {
      // this.webcamAV = true;
      this.isRecord = true;
      this.isStop = false;
      this.isPlay = false;
      this.isPlayPause = false;
      console.log('started recording!');
    });

    /*
        this.player.on('play', event => {
          this.isStop = false;
          this.isRecord = false;
          this.isPlay = false;
          console.log('Is Play Now!');
        });



    */

    // user completed recording and stream is available
    this.player.on('finishRecord', () => {
      // recordedData is a blob object containing the recorded data that
      // can be downloaded by the user, stored on server etc.
      this.webcamAV = true;
      this.isRecord = false;

      this.isStop = true;
      this.isPlayPause = true;

      this.isPlay = false;

      this.accionesService.multimediaData = this.blobToFile(this.player.recordedData, this.player.recordedData.name);
      console.log('finished recording: ', this.player.recordedData);
    });

    this.player.on('waveReady', event => {
      // this.webcamAV = true;
      // this.isStop = true;
      // this.isRecord = false;
      this.accionesService.multimediaData = this.blobToFile(this.player.recordedData, this.player.recordedData.name);
      // const myBar = document.querySelector('.vjs-control-bar') as HTMLElement;
      // myBar.click();
      console.log(this.accionesService.multimediaData);
      console.log('waveform is ready! HOLA!!!');

    });

    this.player.on('pause', event => {
      console.log('playback pause.');
      this.isStop = true;
      this.isPlayPause = true;

      this.isRecord = false;
      this.isPlay = false;
    });

    // error handling
    this.player.on('error', (element, error) => {
      console.warn(error);
    });

    this.player.on('deviceError', () => {
      console.error('device error:', this.player.deviceErrorCode);
    });

    // enumerate devices once
    this.player.one('deviceReady', () => {
      this.player.record().enumerateDevices();
    });

    this.player.on('enumerateReady', event => {
      const devicesArr = this.player.record().devices;

      let count = 0;
      for (let i = 0; i !== devicesArr.length; ++i) {
        if (devicesArr[i].kind === 'videoinput') {
          console.log('Found video input device: ', devicesArr[i].label);
          this.devices[count] = devicesArr[i];
          count++;
        }
      }

      if (this.devices.length > 0) {
        this.player.record().setVideoInput(this.devices[0].deviceId);
        this.selectedDeviceIndex = 0;
      }

      console.log('enumerateReady', this.devices);
    });

    this.player.on('enumerateError', event => {
      console.warn('enumerate error:', this.player.enumerateErrorCode);
    });

    this.player.on('deviceError', event => {
      console.warn('device error:', this.player.deviceErrorCode);
    });
  }

  public blobToFile = (theBlob: Blob, fileName: string): File => {
    return new File([theBlob], fileName, {lastModified: new Date().getTime(), type: theBlob.type});
  }


  playbackStart(): void {
    console.log('Function playbackStart.');

    this.player.play();

    this.isStop = false;
    this.isPlayPause = false;

    this.isRecord = false;
    this.isPlay = true;
  }

  playbackPause(): void {
    console.log('Function playbackPause.');

    this.player.pause();

    this.isStop = false;
    this.isPlayPause = true;

    this.isRecord = false;
    this.isPlay = false;

  }

  changeDevice(): void {
    if (this.devices.length > 0) {
      this.selectedDeviceIndex++;
      if (this.selectedDeviceIndex === this.devices.length) {
        this.selectedDeviceIndex = 0;
      }
      this.player.record().setVideoInput(this.devices[this.selectedDeviceIndex].deviceId);
    }
  }
}
