import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {LocationService} from '../../services/api/tareas/geolocalizacion/location.service';
import {PullToRefreshService} from '@piumaz/pull-to-refresh';
import {TasksService} from '../../services/api/tareas/tasks.service';
import {TaskModel, User} from '../../models';
import {ConfEmpresaModel} from '../../models/conf-empresa.model';
import {Subscription} from 'rxjs';
import {StorageService} from '../../services/storage.service';
import {GLOBAL} from '../../services/global';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MessagingService} from '../../services/firebase/messaging.service';
import {AccionesService} from '../../services/api/tareas/acciones/acciones.service';
import {environment} from '../../../environments/environment';
import {GpsLocationModel} from '../../models/gps-location.model';
import {UserService} from '../../services/api/user/user.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import Swal from 'sweetalert2';
// import {Subject} from 'rxjs';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ZXingScannerComponent} from '@zxing/ngx-scanner';
import {BarcodeFormat} from '@zxing/library';
import {MatSnackBar} from '@angular/material/snack-bar';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  public taskSelected: Subject<TaskModel> = new Subject<TaskModel>();
  public taskActionSelected: Subject<string> = new Subject<string>();

  public currentUser: User;
  public userLocation: GpsLocationModel;

  public tasks: TaskModel[] = [];
  public pendingTasks: TaskModel[] = [];
  public attendedTasks: TaskModel[] = [];
  public closedTasks: TaskModel[] = [];
  public tasksPendingStatus = [1];
  public tasksOpenStatus = [2];
  public tasksClosedStatus = [2, 3, 4];
  public pullRefreshActive: boolean;
  public pullRefreshEnabled: boolean;
  private pullRefreshSubscription: Subscription = Subscription.EMPTY;
  private pushMessageSubscription: Subscription = Subscription.EMPTY;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public locationService: LocationService,
    private pullToRefreshService: PullToRefreshService,
    private userService: UserService,
    private storageService: StorageService,
    private _tasksService: TasksService,
    private messagingService: MessagingService,
    public accionesService: AccionesService,
    private deviceService: DeviceDetectorService,
    public qrDialog: MatDialog,
    public snackbar: MatSnackBar) {

    this.currentUser = this.storageService.getObject(GLOBAL.KEY_CURRENT_USER, '');
    this.pullRefreshActive = true;
    this.pullRefreshEnabled = true;

    this.pullRefreshSubscription = pullToRefreshService.refresh$().subscribe(() => {
      this.getCompanyConfig();
    });

    this.userService.currentUserSubject.subscribe((user) => {
      console.log('currentUserStatus: ', user);
      if (user === null) {
        this.pullRefreshSubscription.unsubscribe();
        this.pushMessageSubscription.unsubscribe();
      }
    });
  }

  ngOnInit(): void {
    this.getDeviceData();
    this.getLocation();
    this.managePushNotifications();
    this.getDataFromServer();

    this.qrDialog.afterAllClosed.subscribe(() => {
      if (this.pullRefreshEnabled) {
        this.pullRefreshActive = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.pullRefreshSubscription.unsubscribe();
    this.pushMessageSubscription.unsubscribe();
  }

  onPullRefreshStatusChange(status: boolean): void {
    if (this.pullRefreshEnabled) {
      this.pullRefreshActive = status;
    }
  }

  onMenuItemClicked(menuItem): void {
    if (menuItem === GLOBAL.MENU.SCAN_QR) {
      this.onScanQRClick();
    } else if (menuItem === GLOBAL.MENU.CREATE_TASK) {
      this.taskActionSelected.next(GLOBAL.TASK_ACTION.CREATE_TASK);
    } else if (menuItem === GLOBAL.MENU.CREATE_INCIDENT) {
      this.taskActionSelected.next(GLOBAL.TASK_ACTION.CREATE_INCIDENT);
    } else if (menuItem === GLOBAL.MENU.UPDATE_TASK_LIST) {
      this.getCompanyConfig();
    }
  }

  onScanQRClick(taskRequested = null): void {
    console.log('onScanQRClick');
    this.pullRefreshActive = false;
    const dialogRef = this.qrDialog.open(DialogQRScanComponent, {
      data: {qr: {}}
    });

    dialogRef.afterClosed().subscribe(qrRead => {
      console.log('qrRead', qrRead);
      if (qrRead !== undefined && qrRead !== null) {
        let processActionTask = true;

        // Si la lectura arranco desde una tarea y el qr de la tare no corresponde al qr leido, se rechaza la lectura
        if (taskRequested !== null && taskRequested.articulo_unidad?.codigo_qr !== qrRead) {
          processActionTask = false;
          this.snackbar.open('El QR de la tarea no coincide con el QR leído.', '', {
            duration: 3000
          });
        }

        if (processActionTask) {
          let filteredTask = null;

          if (taskRequested === null) {
            filteredTask = this.tasks.filter(t => t.articulo_unidad?.codigo_qr === qrRead &&
              (t.id_estado === 1 || t.id_estado === 2));
          } else {
            filteredTask = [taskRequested];
          }

          if (filteredTask.length === 0) {
            // TODO: Crear tarea
            this.snackbar.open('No se encontró tarea para el QR', '', {
              duration: 3000
            });
          } else if (filteredTask.length === 1) {
            this.sendTaskFromScan(filteredTask[0]);
          } else {
            filteredTask = this._tasksService.shortTask(filteredTask, 'desc', 'fecha_programada');
            this.showDialogSelectTask(filteredTask);
          }
        }
      } else {
        this.snackbar.open('No se obtuvo ningun QR', '', {
          duration: 3000
        });
      }
    });
  }

  showDialogSelectTask(filteredTask): void {
    this.qrDialog.closeAll();
    this.pullRefreshActive = false;
    const dialogRef = this.qrDialog.open(DialogSelectTaskComponent, {
      data: {tasks: filteredTask}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result !== undefined && result !== null) {
        this.sendTaskFromScan(result);
      } else {
        this.snackbar.open('No seleccionó la tarea', '', {
          duration: 3000
        });
      }
    });
  }

  sendTaskFromScan(task): void {
    this.taskSelected.next(task);
  }

  getLocation(): any {
    this.locationService.getPositionIsPermissionGranted().then(pos => {
      this.userLocation = {
        latitude: pos.latitude,
        longitude: pos.longitude,
        accuracy: pos.accuracy
      };

      this.storageService.setObject(GLOBAL.KEY_CURRENT_USER_LOCATION, this.userLocation);
      console.log('User location = ', this.userLocation);
    }).catch(error => {
      console.log('Location error: ', error);
    });
  }

  getDataFromServer(): void {
    console.log('getDataFromServer');
    let lastDateSync = this.storageService.getObject(GLOBAL.KEY_LAST_SYNC_SERVER, null);
    if (lastDateSync === null) { // Si nunca sincronizo, sincroniza
      this.getCompanyConfig();
    } else {
      const currentTime = new Date();
      lastDateSync = new Date(lastDateSync);
      console.log('lastDateSync', lastDateSync);
      console.log('currentTime', currentTime);

      this.updateLocalTasksList(this.storageService.getObject(GLOBAL.KEY_LAST_TASK_LIST, []));
      this.updateTaskOnList();

      // Verifica si ya pasaron 5 minutos desde que syncronizo la ultima vez para volver a syncronizar
      if ((currentTime.getTime() - lastDateSync.getTime()) / 60000 >= 5) {
        this.getCompanyConfig();
      }
    }
  }

  getCompanyConfig(): void {
    this.userService.getConfEmpresa().subscribe(
      result => {
        if (result.status !== 'ok') {
          console.log('Error al obtener la configuracion de la empresa (status !== ok): ', result);

        } else {
          console.log(result);
          const ConfEmpresa: Array<ConfEmpresaModel> = result.data as Array<ConfEmpresaModel>;

          if (ConfEmpresa != null) {
            this.storageService.setObject(GLOBAL.KEY_COMPANY_CONFIG, ConfEmpresa);
          }
        }
        this.getTasksList();
      },
      error => {
        console.log('Error al obtener la configuracion de la empresa: ', error);
      }
    );
  }

  getTasksList(url = null): void {
    this._tasksService.getSyncTasks(null, url).subscribe(
      result => {
        console.log('HomeComponent | getTasksList | result', result);
        if (result.status !== 'ok') {
        } else {

          if (result.data.prev_page_url === null) {
            this.updateLocalTasksList(result.data.data);
          } else {
            result.data.data.forEach(task => {
              this.tasks[this.tasks.length] = task;
            });
            this.updateLocalTasksList(this.tasks);
          }

          if (result.data.next_page_url === null) {
            this.snackbar.open('Lista de tareas actualizadas.', '', {
              duration: 3000
            });
            this.storageService.setObject(GLOBAL.KEY_LAST_SYNC_SERVER, new Date());
          } else {
            this.getTasksList(result.data.next_page_url.replace(environment.apiBaseUrl, ''));
          }
        }

      },
      error => {
        console.log('HomeComponent | getTasksList | error', error);
      }
    );
  }

  private updateLocalTasksList(tasks): void {
    this.tasks = tasks;
    this.pendingTasks = this._tasksService.shortTask(tasks.filter(task => task.id_estado === 1), 'asc', 'fecha_programada');
    this.attendedTasks = this._tasksService.shortTask(tasks.filter(task => task.id_estado === 2 && task.tipo_accion !== 'INCIDENTE'), 'desc', 'fecha_hasta');
    this.closedTasks = this._tasksService.shortTask(tasks.filter(
      task => task.id_estado === 3 || task.id_estado === 4 || task.tipo_accion === 'INCIDENTE'
    ), 'desc', 'fecha_hasta');
    this.storageService.setObject(GLOBAL.KEY_LAST_TASK_LIST, tasks);
  }

  /*
   * Actualiza la tarea cacheada con la tarea recien actulizada.
   */
  private updateTaskOnList(): void {
    console.log('updateTaskOnList', this.accionesService.tarea);
    this.activatedRoute.params.forEach((params: Params) => {
      if (params.action === 'updateTask' && !(this.accionesService.tarea === null || this.accionesService.tarea === undefined)) {
        console.log(this.accionesService.tarea);
        this.updateLocalTasksList(this._tasksService.updateTaskOnList(this.tasks, this.accionesService.tarea));
        this.accionesService.destroy();
        this.router.navigate(['/home']);
      }
    });
  }

  private managePushNotifications(): void {
    this.messagingService.requestPermission(this.currentUser.id);
    this.messagingService.receiveMessage();

    this.pushMessageSubscription = this.messagingService.onMessageReceived.subscribe((message_type) => {
      if (message_type === GLOBAL.FCM_PUSH_NEW_TASK_RECEIVE) {
        this.storageService.setObject(GLOBAL.KEY_LAST_SYNC_SERVER, null);
        this.getDataFromServer();
      }
    });
  }

  private getDeviceData(): void {
    const deviceInfo: any = this.deviceService.getDeviceInfo();
    const deviceInfoAux: any = {
      browser: deviceInfo.browser,
      browser_version: deviceInfo.browser_version,
      device: deviceInfo.device,
      deviceType: deviceInfo.deviceType,
      orientation: deviceInfo.orientation,
      os: deviceInfo.os,
      os_version: deviceInfo.os_version,
      userAgent: deviceInfo.userAgent
    };

    if (deviceInfoAux.browser.toLowerCase() === 'safari') {
      this.pullRefreshActive = false;
      this.pullRefreshEnabled = false;
    }

    deviceInfoAux.isMobile = this.deviceService.isMobile();
    deviceInfoAux.isTablet = this.deviceService.isTablet();
    deviceInfoAux.isDesktop = this.deviceService.isDesktop();
    console.log('Device Info: ', deviceInfoAux);
    this.storageService.setObject(GLOBAL.KEY_DEVICE_INFO, deviceInfoAux);

    try { // @ts-ignore
      const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      console.log('Connection:', connection);
      deviceInfoAux.network_type = connection.effectiveType;
      this.storageService.setObject(GLOBAL.KEY_DEVICE_INFO, deviceInfoAux);
    } catch (e) {
      // console.error(e);
    }

    try { // @ts-ignore
      navigator.getBattery().then((battery) => {
        deviceInfoAux.battery_status = battery.level;
        console.log('Battery Status: ', deviceInfoAux.battery_status);
        this.storageService.setObject(GLOBAL.KEY_DEVICE_INFO, deviceInfoAux);
      });
    } catch (e) {
      // console.error(e);
    }
  }
}

@Component({
  selector: 'app-dialog-qr-sacan',
  templateUrl: 'dialog-qr-sacan.html',
})
export class DialogQRScanComponent implements OnInit {

  public currentDevice: MediaDeviceInfo;
  public availableDevices;
  public hasDevices;
  public allowedFormats;
  public hasPermission;


  @ViewChild(ZXingScannerComponent) scanner: ZXingScannerComponent;

  constructor(
    public dialogRef: MatDialogRef<DialogQRScanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {

  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  scanSuccessHandler($event): void {
    console.log($event);
    this.data = $event;
    this.closeDialog(this.data);
  }

  handleScanError($event): void {
    console.log('Error: ', $event);
  }

  onNoClick(): void {
    this.closeDialog();
  }

  closeDialog(data = null): void {
    this.scanner.enable = false;
    this.scanner.scanStop();
    this.dialogRef.close(data);
  }
}

@Component({
  selector: 'app-dialog-select-task',
  templateUrl: 'dialog-select-task.html',
})
export class DialogSelectTaskComponent implements OnInit {

  public tasks: TaskModel[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogSelectTaskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.tasks = this.data.tasks;
  }

  selectTask(task): void {
    this.closeDialog(task);
  }

  onNoClick(): void {
    this.closeDialog();
  }

  closeDialog(data = null): void {
    this.dialogRef.close(data);
  }
}
