import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {ToastrModule} from 'ngx-toastr';
import {InterceptorService} from './interceptors/interceptor.service';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './components/login/login.component';
import {DialogQRScanComponent, DialogSelectTaskComponent, HomeComponent} from './components/home/home.component';
import {TaskListComponent, DialogTaskActionsComponent, DialogTaskObservationsComponent} from './components/tareas/task-list/task-list.component';
import {CrearTareaComponent} from './components/tareas/crear-tarea/crear-tarea.component';
import {LectorQrComponent} from './components/tareas/lector-qr/lector-qr.component';
import {AgregarAccionComponent} from './components/tareas/agregar-accion/agregar-accion.component';
import {NavBarComponent} from './components/nav-bar/nav-bar.component';
import {MmFotoComponent} from './components/tareas/multimedia/mm-foto/mm-foto.component';
import {WebcamModule} from './components/tareas/multimedia/webcam/webcam.module';
import {FormsModule} from '@angular/forms';
import {MmAudioComponent} from './components/tareas/multimedia/mm-audio/mm-audio.component';
import {MmVideoComponent} from './components/tareas/multimedia/mm-video/mm-video.component';
import {VideojsRecordComponent} from './components/tareas/multimedia/videojs-record/videojs-record.component';
import {AudiojsRecordComponent} from './components/tareas/multimedia/audiojs-record/audiojs-record.component';
import {FooterComponent} from './components/footer/footer.component';
import {PullToRefreshModule} from '@piumaz/pull-to-refresh';
import {CustomDatePipe} from './modules/custom.datepipe';
import {CustomTimerPipe} from './modules/custom.timerpipe';
import {OverlayModule} from '@angular/cdk/overlay';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {SpinnerOverlayComponent} from './components/utils/spinner-overlay/spinner-overlay.component';
import {SpinnerInterceptor} from './interceptors/spinner.interceptor';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import {ReportProductsComponent} from './components/tareas/report-products/report-products.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {HistoricTaskComponent} from './components/tareas/historic-task/historic-task.component';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {environment} from '../environments/environment';
import {AngularFireModule} from '@angular/fire';
import {MessagingService} from './services/firebase/messaging.service';
import {MatMenuModule} from '@angular/material/menu';
import {MatListModule} from '@angular/material/list';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialogModule} from '@angular/material/dialog';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NgxSignaturePadModule} from '@eve-sama/ngx-signature-pad';
import { SignaturePadComponent } from './components/tareas/multimedia/signature-pad/signature-pad.component';
import {IconComponent} from './components/tareas/multimedia/signature-pad/icon.component';


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    LoginComponent,
    HomeComponent,
    DialogQRScanComponent,
    DialogSelectTaskComponent,
    TaskListComponent,
    CrearTareaComponent,
    LectorQrComponent,
    AgregarAccionComponent,
    NavBarComponent,
    MmFotoComponent,
    MmAudioComponent,
    MmVideoComponent,
    SignaturePadComponent,
    IconComponent,
    VideojsRecordComponent,
    AudiojsRecordComponent,
    CustomDatePipe,
    CustomTimerPipe,
    SpinnerOverlayComponent,
    ReportProductsComponent,
    HistoricTaskComponent,
    DialogTaskActionsComponent,
    DialogTaskObservationsComponent,
    SignaturePadComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule, // required animations module
    FormsModule,
    WebcamModule,
    ToastrModule.forRoot(),
    PullToRefreshModule,
    OverlayModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatMenuModule,
    MatListModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatTabsModule,
    MatDialogModule,
    MatSnackBarModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    ZXingScannerModule,
    NgxSignaturePadModule
  ],
  providers: [
    MessagingService,
    {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true}
  ], bootstrap: [AppComponent]
})
export class AppModule {
}
