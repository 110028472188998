<app-nav-bar [showBack]="true"></app-nav-bar>
<div style="background-color: black; height: 100vh; top: 0; position:fixed; overflow: hidden">
  <div style="width: 100%; height: 100%; background: black; position: fixed">
    <app-audiojs-record></app-audiojs-record>

    <div class="row option-control">
      <div class="col-6" style="text-align: center">
        <button class="btn btn-secondary btn-110" (click)="goToBack();">Cancelar</button>
      </div>
      <div class="col-6">
        <button *ngIf="this.accionesService.multimediaData" class="btn btn-110 btn-accent" (click)="send_audio();">Reportar</button>
      </div>
    </div>
  </div>
</div>
