<div class="webcam-wrapper" (click)="imageClick.next();">
  <video #video style="height: 100vh; width: 100vw"
         [width]="videoWidth"
         [height]="videoHeight"
         [class]="videoStyleClasses"
         autoplay
         muted
         playsinline
         (resize)="videoResize()">

  </video>
  <div class="camera-switch" *ngIf="allowCameraSwitch && availableVideoInputs.length > 1 && videoInitialized" (click)="rotateVideoInput(true)"></div>
  <canvas #canvas [width]="width" [height]="height"></canvas>
</div>
