<app-nav-bar [showBack]="true"></app-nav-bar>
<div *ngIf="reportedTasks" style="padding-bottom: 25px">
  <ng-container *ngFor="let task of reportedTasks">
    <ng-container>
      <mat-card style="margin: 10px 10px 0 10px;">
        <div class="row task-list-row">
          <div class="col-10 caption">
            <h3>{{task.accion_descripcion}}</h3>
            <div class="az-task-icon-text">
              <mat-icon aria-hidden="false" aria-label="Fecha Inicio">schedule</mat-icon>
              <div class="az-task-text">
                {{task.fecha_hora | pyDate | date: 'dd/MM/yyyy hh:mm a'}} <br><br>
              </div>
            </div>
          </div>
          <div class="col-1">

            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button *ngIf="task.observacion" mat-menu-item (click)="viewObservations(task)">
                <mat-icon>speaker_notes</mat-icon>
                <span>Observación</span>
              </button>
              <button mat-menu-item (click)="openMap(task)">
                <mat-icon>location_on</mat-icon>
                <span>Ver en mapa</span>
              </button>
              <button *ngIf="task.checks_acciones_stocks.length>0" mat-menu-item (click)="viewItems(task)">
                <mat-icon>category</mat-icon>
                <span>Items reportados</span>
              </button>
            </mat-menu>


          </div>
        </div>
      </mat-card>
    </ng-container>
  </ng-container>
</div>


<!-- Modal -->
<div class="modal fade" id="historicObservationsModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="historicObservationsModalLongTitle">Observación</h3>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row" style="text-align: justify;">
            {{selectedReportedTask?.observacion}}
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-accent" data-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="historicItemsModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="historicItemsModalLongTitle">Items reportados</h3>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <mat-list>
            <ng-container *ngFor="let item of selectedReportedTask?.checks_acciones_stocks">
              <mat-list-item style="height: auto;"
                *ngIf="item.stock_item.id_unidad_medida!==checkBoxTypeID || (item.stock_item.id_unidad_medida===checkBoxTypeID && item.cantidad>0)">
                <mat-icon mat-list-icon>category</mat-icon>
                <div mat-line style="white-space: pre-wrap;">
                  {{item.stock_item?.nombre}}:
                  <span *ngIf="item.stock_item.id_unidad_medida!==checkBoxTypeID">{{item.cantidad}} {{item.stock_item.unidad_medida.simbolo}}</span>
                  <span *ngIf="item.stock_item.id_unidad_medida===checkBoxTypeID && item.cantidad>0">
                    <mat-icon mat-list-icon>check</mat-icon>
                  </span>
                </div>
              </mat-list-item>
            </ng-container>
          </mat-list>
        </div>
      </div>
      <div class="modal-footer">
        <button *ngIf="this.task?.id_estado === taskStatusCantEditProducts" type="button" class="btn btn-secondary" (click)="updateStock(selectedReportedTask);" data-dismiss="modal">Editar</button>
        <button type="button" class="btn btn-accent" data-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>
