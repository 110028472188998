import {Component, OnInit} from '@angular/core';
import {AudiojsRecordComponent} from '../audiojs-record/audiojs-record.component';
import {Observable, Subject, Observer, Subscription} from 'rxjs';
import {AgregarAccionService} from '../../../../services/api/tareas/agregar-accion/agregar-accion.service';
import {ActivatedRoute, Router, Params} from '@angular/router';
import {LocationService} from '../../../../services/api/tareas/geolocalizacion/location.service';
import {StorageService} from '../../../../services/storage.service';
import {AccionesService} from '../../../../services/api/tareas/acciones/acciones.service';
import {formatDate, Location} from '@angular/common';
import videojs from 'video.js';

/*import {FormBuilder, FormGroup, Validators} from '@angular/forms';*/

@Component({
  selector: 'app-mm-audio',
  templateUrl: './mm-audio.component.html',
  styleUrls: ['./mm-audio.component.css']
})
export class MmAudioComponent implements OnInit {

  public webcamAV: any;
  public AudioRecord = AudiojsRecordComponent;
  public myData: Subscription;
  public myDataValue: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public accionService: AgregarAccionService,
    public accionesService: AccionesService,
    public locationService: LocationService,
    private storage: StorageService,
    private location: Location
  ) {

  }

  ngOnInit(): void {
    if (this.accionesService.tarea === undefined) {
      this.router.navigate(['/home']);

    } else {
      this.webcamAV = true;
      // this.myData = this.accionesService.multimediaData.subscribe(v => this.myDataValue = v);
      console.log('mm-audio webcamAV TEST' + this.webcamAV);
    }
  }

  send_audio(): void {
    this.accionesService.calledFrom = 'mm-audio';
    /*this.AccionesService.imgB64 = this.webcamImage.imageAsBase64;
    this.AccionesService.imgUrl = this.webcamImage.imageAsDataUrl;
    this.base64TrimmedURL = this.webcamImage.imageAsBase64;
    this.createBlobImageFile();*/
    // this.accionesService.multimediaData = this.webcamImage.imageData;
    // Obtener fecha hora para concatenar a la descipcion

    this.accionesService.multimedia = JSON.stringify({
      tipo: 'Audio',
      campo: 'archivo'
    });

    this.router.navigate(['/agregar-accion', this.accionesService.idTarea, this.accionesService.tipoTarea]);
  }

  goToBack(): void {
    this.accionesService.destroy();
    // this.location.back();
    this.router.navigate(['/home']);
  }

}


