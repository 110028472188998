<audio id="audio_{{idx}}" class="video-js vjs-default-skin vjs-control-bar-lg" style="background: #7d4bd5; height: calc(100vh - 175px); position: fixed; top: 0; overflow: hidden"></audio >

<div class="form-group" style="padding: 10px; display: none" id="btn_multi">
  <div class="container">
    <div class="" style="text-align: center;">
      <div>
        <img style="width: 170px;" src="assets/images/record_audio.jpg" alt="record-audio">
      </div>

      <div class="col-xs-6 col-sm-4 col-md-4 col-lg-3" style="margin-top: 20px;">
        <a id="start" *ngIf="!isRecord" (click)="player.record().start()">
          <img src="/assets/icons/record-circle.svg" alt="Bootstrap" width="50" height="50">
        </a>
        <a id="stop" *ngIf="isRecord" (click)="player.record().stop()">
          <img src="/assets/icons/stop-circle.svg" alt="Bootstrap" width="50" height="50">
        </a>
        <a style="margin-left: 15px;" id="play" *ngIf="isStop || isPlayPause" (click)="playbackStart()">
          <img src="/assets/icons/play-circle.svg" alt="Bootstrap" width="50" height="50">
        </a>
        <a style="margin-left: 15px;" id="pause" *ngIf="isPlay" (click)="playbackPause()">
          <img src="/assets/icons/stop-circle-fill.svg" alt="Bootstrap" width="50" height="50">
        </a>
      </div>
      <hr>
    </div>
  </div>
</div>




