export let GLOBAL = {
  TASK_STATUS_ATTENDED: '2',
  TASK_STATUS_CLOSE: '3',
  TASK_STATUS_CANCEL: '4',

  KEY_LAST_SYNC_SERVER: 'KEY_LAST_SYNC_SERVER',
  KEY_COMPANY_CONFIG: 'Config-Empresa',
  KEY_CURRENT_USER: 'currentUser',
  KEY_CURRENT_USER_LOCATION: 'KEY_CURRENT_USER_LOCATION',
  KEY_CURRENT_USER_FCM_TOKEN: 'KEY_CURRENT_USER_FCM_TOKEN',
  KEY_LAST_TASK_LIST: 'KEY_LAST_TASK_LIST',
  KEY_DEVICE_INFO: 'KEY_DEVICE_INFO',

  FCM_PUSH_NEW_TASK_RECEIVE: 'FCM_PUSH_NEW_TASK_RECEIVE',
  FCM_PUSH_GENERIC: 'FCM_PUSH_GENERIC',

  MENU: {
    SCAN_QR: 'SCAN_QR',
    ENABLE_LOCATION: 'ENABLE_LOCATION',
    CREATE_TASK: 'CREATE_TASK',
    CREATE_INCIDENT: 'CREATE_INCIDENT',
    UPDATE_TASK_LIST: 'UPDATE_TASK_LIST',
  },

  TASK_ACTION: {
    CREATE_TASK: 'CREATE_TASK',
    CREATE_INCIDENT: 'CREATE_INCIDENT',
  },

  url: '',
  color_accent: '#7d4bd5',
  text_default_error_msg: 'Ha ocurrido un error, por favor inténtelo de nuevo más tarde',
  format_date_time: 'YYYY-MM-DD HH:mm:ss',
};
