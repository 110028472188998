import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {ItemResponseModel, StockGrupoModel, UnidadMedidaModel} from '../../../models/stock-grupo.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AccionesService} from '../../../services/api/tareas/acciones/acciones.service';
import * as moment from 'moment';
import {GLOBAL} from '../../../services/global';
import {TasksService} from '../../../services/api/tareas/tasks.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-report-products',
  templateUrl: './report-products.component.html',
  styleUrls: ['./report-products.component.css']
})
export class ReportProductsComponent implements OnInit {
  public displayedColumns = ['nombre', 'valor', 'simbolo'];
  public dataSource;
  public title = '';
  public stock: StockGrupoModel;

  public reportarRequerido = false;
  public reportarCeros = false;
  public updateStock = false;
  public reportedActionID = null;

  public checkBoxTypeID = 17;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private tasksService: TasksService,
    public accionesService: AccionesService) {

    this.stock = this.accionesService.stock;
    console.log('ReportProductsComponent');
  }

  ngOnInit(): void {
    if (this.stock === undefined) {
      this.router.navigate(['/home']);
    } else {
      console.log('Lista de productos a reportar: ', this.stock);
      this.stock.items.forEach(item => {
        if (item.valor === undefined || item.valor === null) {
          item.valor = 0;
        }
      });

      this.title = this.stock.nombre;
      const availableItems = [];
      this.stock.items.forEach(item => {
        if (item.borrado === 0) {
          availableItems[availableItems.length] = item;
        }
      });

      availableItems.sort((a, b) => {
        if (a.nombre.toUpperCase() < b.nombre.toUpperCase()) {
          return -1;
        }
        if (a.nombre.toUpperCase() > b.nombre.toUpperCase()) {
          return 1;
        }
        return 0;
      });

      this.dataSource = new MatTableDataSource(availableItems);
    }

    this.activatedRoute.params.forEach((params: Params) => {
      if (params.reportarRequerido === 1) {
        this.reportarRequerido = true;
      }

      if (params.reportarCeros === 1) {
        this.reportarCeros = true;
      }
    });

    this.activatedRoute.queryParams.subscribe(params => {
      this.updateStock = params.updateStock;
      this.reportedActionID = Number(params.reportedActionID);
    });
  }

  applyFilter(filterValue: string): void {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  reportStock(attach = false): void {
    this.accionesService.calledFrom = 'report-stock';

    const dateTime = (moment(new Date())).format(GLOBAL.format_date_time);

    const itemResponse: ItemResponseModel[] = [];
    this.stock.items.forEach(item => {
      const valor: any = item.valor;
      if (valor === null) {
        item.valor = 0;
      } else if (valor === true) {
        item.valor = 1;
      } else if (valor === false) {
        item.valor = 0;
      }

      itemResponse[itemResponse.length] = {
        id_item: item.id,
        cantidad: item.valor,
        fecha: dateTime
      };
    });

    this.accionesService.stockReport = itemResponse;

    if (!this.updateStock) {
      let queryParam = {};
      if (attach) {
        queryParam = {queryParams: {attach: true}};
      }
      this.router.navigate(['/agregar-accion', this.accionesService.idTarea, this.accionesService.tipoTarea], queryParam);
    } else {
      this.accionesService.tarea.acciones_reportadas.forEach(accion => {
        accion.fecha = accion.fecha_hora;
        accion.latitud = accion.usuario_latitud;
        accion.longitud = accion.usuario_longitud;
        accion.accuracy = accion.usuario_accuracy;
        if (accion.id === this.reportedActionID) {
          accion.stock = itemResponse;
          accion.checks_acciones_stocks = itemResponse;
        }
      });

      this.tasksService.updateTask(this.accionesService.tarea)
        .then(
          result => { // Success
            Swal.fire({
              title: 'TaskTrack',
              text: 'Proceso realizado con éxito',
              icon: 'success',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: GLOBAL.color_accent
            }).then((alertResult) => {
              this.accionesService.destroy();
              this.accionesService.tarea = result;
              this.router.navigate(['/home/updateTask']);
            });

          },
          result => { // Error
            console.log('agregar-accion reportAction result error ', result);
            Swal.fire({
              title: 'TaskTrack',
              text: GLOBAL.text_default_error_msg,
              icon: 'error',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: GLOBAL.color_accent
            }).then((alertResult) => {

            });
          }
        );
    }
  }

  goToBack(): void {
    this.accionesService.destroy();
    // this.location.back();
    this.router.navigate(['/home']);
  }
}
