import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GLOBAL} from '../../services/global';
import {TaskModel, User} from '../../models';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {StorageService} from '../../services/storage.service';
import {LocationService} from '../../services/api/tareas/geolocalizacion/location.service';
import {GpsLocationModel} from '../../models/gps-location.model';
import Swal from 'sweetalert2';
import {UserService} from '../../services/api/user/user.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  @Input() showBack: boolean;
  @Input() showRequestLocation = false;
  @Input() showUpdateTaskList = false;
  @Input() showCreateTask = false;
  @Input() showCreateIncident = false;
  @Input() showQRScanner = false;
  @Output() public menuClickEvent: EventEmitter<string> = new EventEmitter<string>();

  public currentUser: User;
  public userLocation: GpsLocationModel;
  public MENU = GLOBAL.MENU;

  constructor(
    private router: Router,
    private userService: UserService,
    private storageService: StorageService,
    public locationService: LocationService,
    private location: Location
  ) {
    this.userService.currentUser.subscribe(x => this.currentUser = x);
  }


  ngOnInit(): void {
    if (navigator.permissions) {
      navigator.permissions.query({name: 'geolocation'})
        .then(permissions => {
          this.showRequestLocation = permissions.state !== 'granted';
        });
    } else {
      if (this.storageService.getObject(GLOBAL.KEY_CURRENT_USER_LOCATION, null) !== null) {
        this.showRequestLocation = false;
      }
    }
  }

  onMenuItemClicked(item): void {
    if (item === this.MENU.ENABLE_LOCATION) {
      this.getLocation();
    } else {
      this.menuClickEvent.emit(item);
    }
  }

  getLocation(): any {
    if (navigator.permissions) {
      navigator.permissions.query({name: 'geolocation'})
        .then(permissions => {
          if (permissions.state !== 'denied') {
            this.getLocationService();
          } else {
            Swal.fire({
              title: 'Atención',
              text: 'Primero debe habilitar la opción en el navegador.',
              icon: 'warning',
              confirmButtonText: 'Aceptar',
              confirmButtonColor: GLOBAL.color_accent
            });
          }
        });
    } else {
      this.getLocationService();
    }
  }

  getLocationService(): void {
    this.locationService.getPosition().then(pos => {
      this.userLocation = {
        latitude: pos.latitude,
        longitude: pos.longitude,
        accuracy: pos.accuracy
      };

      this.storageService.setObject(GLOBAL.KEY_CURRENT_USER_LOCATION, this.userLocation);
      this.showRequestLocation = false;
      console.log('User location = ', this.userLocation);
    }).catch(error => {
      console.log('Location error: ', error);
    });
  }

  logout(): void {
    this.userService.logout();
    this.router.navigate(['/login']);
  }

  goToBack(): void {
    this.router.navigate(['/home']);
  }
}
