import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon',
  template: `
    <svg
      *ngIf="type === 'big'"
      t="1599748271289"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="1432"
      width="200"
      height="200"
    >
      <path
        d="M162.771044 339.15879v-141.861463c0-17.462946 15.275083-32.738029 32.728039-32.738029h141.861463c13.09721 0 21.828683-8.721483 21.828683-21.818693v-21.788722c0-13.09721-8.731473-21.818693-21.828683-21.818693H130.043005c-17.462946 0-32.738029 15.275083-32.738029 32.728039v207.297561c0 13.08722 8.731473 21.818693 21.828683 21.818693h21.818692c13.08722 0 21.818693-8.731473 21.818693-21.818693z m523.688585-174.569522h141.861464c17.452956 0 32.728039 15.275083 32.728039 32.73803v141.861463c0 13.08722 8.731473 21.818693 21.818692 21.818693h21.818693c13.09721 0 21.828683-8.731473 21.828683-21.818693V131.87122c0-17.452956-15.275083-32.728039-32.738029-32.72804H686.459629c-13.08722 0-21.818693 8.721483-21.818692 21.818693v21.818693c0 13.067239 8.701502 21.808702 21.818692 21.808702zM337.330576 862.847376h-141.861464c-17.452956 0-32.728039-15.275083-32.728039-32.728039V688.327805c0-13.09721-8.731473-21.818693-21.818693-21.818693h-21.788721c-13.09721 0-21.828683 8.721483-21.828683 21.818693v207.297561c0 17.452956 15.275083 32.728039 32.738029 32.728039h207.287571c13.09721 0 21.828683-8.731473 21.828683-21.818693v-21.868644c0-13.08722-8.731473-21.818693-21.828683-21.818692zM861.029151 688.327805v141.861463c0 17.452956-15.275083 32.728039-32.728039 32.728039h-141.861463c-13.08722 0-21.818693 8.731473-21.818693 21.818693v21.828683c0 13.08722 8.731473 21.818693 21.818693 21.818693h207.297561c17.462946 0 32.738029-15.275083 32.738029-32.728039V688.327805c0-13.09721-8.731473-21.818693-21.828683-21.818693h-21.798712c-13.08722 0-21.818693 8.721483-21.818693 21.818693z"
        p-id="1433"
      ></path>
      <path
        d="M409.340254 441.708644c-8.721483 0-17.452956-2.177873-23.976586-8.721483l-272.733658-270.575766c-13.08722-13.09721-13.08722-32.728039 0-45.825249s32.738029-13.08722 45.825249 0l274.891551 270.575766c13.08722 13.09721 13.08722 32.728039 0 45.825249-6.54361 6.583571-15.275083 8.721483-24.006556 8.721483z m480.0512 480.06119c-8.721483 0-17.452956-2.187863-23.976586-8.731473l-272.733658-270.575766c-13.08722-13.09721-13.08722-32.728039 0-45.825249s32.738029-13.08722 45.825249 0l274.891551 270.575766c13.09721 13.09721 13.09721 32.728039 0 45.825249-6.54361 6.54361-15.275083 8.731473-23.976586 8.731473z m-752.804839 2.177873c-8.731473 0-17.452956-2.177873-23.976586-8.731473-13.08722-13.08722-13.08722-32.728039 0-45.815258L383.155824 594.419512c13.09721-13.09721 32.738029-13.09721 45.825249 0s13.09721 32.728039 0 45.825249l-268.387902 272.733659c-6.5536 6.54361-15.275083 10.909346-23.976586 10.909346z m480.0512-480.0512c-8.731473 0-17.452956-2.187863-23.976586-8.731473-13.08722-13.08722-13.08722-32.728039 0-45.825249l270.575766-274.931512c13.09721-13.09721 32.738029-13.09721 45.825249 0s13.09721 32.728039 0 45.815259l-268.387903 272.733658c-6.5536 6.54361-15.275083 10.909346-23.976585 10.909347z"
        p-id="1434"
      ></path>
    </svg>
    <svg
      *ngIf="type === 'small'"
      t="1599748258492"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="5485"
      width="200"
      height="200"
    >
      <path
        d="M641 424.6h187.2c25 0 41.6-16.6 41.6-41.6s-16.6-41.6-41.6-41.6h-87.4l174.7-174.7c16.6-16.6 16.6-41.6 0-58.2-8.3-8.3-20.8-12.5-29.1-12.5s-20.8 4.2-29.1 12.5L682.6 283.2v-87.4c0-25-16.6-41.6-41.6-41.6s-41.6 16.6-41.6 41.6V383c0 25 16.6 41.6 41.6 41.6zM283.2 341.4h-87.4c-25 0-41.6 16.6-41.6 41.6s16.6 41.6 41.6 41.6H383c25 0 41.6-16.6 41.6-41.6V195.8c0-25-16.6-41.6-41.6-41.6s-41.6 16.6-41.6 41.6v87.4L166.7 108.5c-8.3-8.3-20.8-12.5-29.1-12.5s-20.8 4.2-29.1 12.5c-16.6 16.6-16.6 41.6 0 58.2l174.7 174.7zM641 869.8c25 0 41.6-16.6 41.6-41.6v-87.4l174.7 174.7c16.6 16.6 41.6 16.6 58.2 0s16.6-41.6 0-58.2L740.8 682.6h87.4c25 0 41.6-16.6 41.6-41.6s-16.6-41.6-41.6-41.6H641c-25 0-41.6 16.6-41.6 41.6v187.2c0 24.9 16.6 41.6 41.6 41.6zM166.7 915.5l174.7-174.7v87.4c0 25 16.6 41.6 41.6 41.6s41.6-16.6 41.6-41.6V641c0-25-16.6-41.6-41.6-41.6H195.8c-25 0-41.6 16.6-41.6 41.6s16.6 41.6 41.6 41.6h87.4L108.5 857.3c-16.6 16.6-16.6 41.6 0 58.2 16.6 16.7 41.6 16.7 58.2 0z"
        p-id="5486"
      ></path>
    </svg>
    <svg
      *ngIf="type === 'clear'"
      t="1599748441929"
      class="icon"
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      p-id="2951"
      width="200"
      height="200"
    >
      <path
        d="M452.225 146.9L111.637 487.488a33.223 33.223 0 0 1-47.132 0c-13.073-13.073-12.9-34.059 0-47.132L405.093 99.768a33.223 33.223 0 0 1 47.132 0 33.223 33.223 0 0 1 0 47.132z m472.007 471.835L583.644 959.323a33.223 33.223 0 0 1-47.132 0c-13.073-13.073-12.901-34.06 0-47.132L877.1 571.603a33.223 33.223 0 0 1 47.132 0c13.073 13.073 12.9 34.23 0 47.132z m-473.039 2.064L348.157 723.835a33.223 33.223 0 0 1-47.132 0c-13.073-13.073-12.901-34.058 0-47.131L404.06 573.667a33.223 33.223 0 0 1 47.132 0c13.073 13.073 13.073 34.23 0 47.132z m-152.749-95.812l-74.654 74.654a33.223 33.223 0 0 1-47.132 0c-13.073-13.073-12.9-34.059 0-47.132l74.655-74.654a33.223 33.223 0 0 1 47.131 0c12.902 13.073 12.902 34.231 0 47.132zM545.285 772l-74.654 74.653a33.223 33.223 0 0 1-47.132 0c-13.073-13.073-12.901-34.058 0-47.131l74.654-74.655a33.223 33.223 0 0 1 47.132 0 33.223 33.223 0 0 1 0 47.132z"
        p-id="2952"
      ></path>
      <path
        d="M536.34 959.323L64.505 487.66a33.223 33.223 0 0 1 0-47.132c13.073-13.073 34.06-12.9 47.132 0l471.835 471.835a33.223 33.223 0 0 1 0 47.132c-13.073 12.9-34.059 12.9-47.132-0.172zM732.264 763.57L260.43 291.737a33.223 33.223 0 0 1 0-47.132c13.073-13.073 34.06-12.901 47.132 0L779.224 716.44a33.223 33.223 0 0 1 0 47.132c-12.901 13.073-34.059 12.9-46.96 0z m180.099-544.08L804.682 111.637a33.223 33.223 0 0 1 0-47.132c13.073-13.073 34.059-12.9 47.132 0l107.68 107.853a33.223 33.223 0 0 1 0 47.132c-13.072 13.073-34.23 13.073-47.131 0zM587.256 328.719L405.437 146.9a33.223 33.223 0 0 1 0-47.132c13.073-13.073 34.06-12.9 47.132 0L634.56 281.76a33.223 33.223 0 0 1 0 47.132c-13.073 12.901-34.23 12.901-47.304-0.172zM877.1 618.563l-181.819-181.82a33.223 33.223 0 0 1 0-47.131c13.073-13.073 34.059-12.901 47.132 0l181.99 181.99a33.223 33.223 0 0 1 0 47.133c-13.072 12.9-34.23 12.9-47.303-0.172z"
        p-id="2953"
      ></path>
      <path
        d="M959.323 219.49L742.24 436.572a33.223 33.223 0 0 1-47.132 0c-13.073-13.073-12.901-34.059 0-47.132L912.19 172.358a33.223 33.223 0 0 1 47.132 0c13.073 12.901 13.073 34.059 0 47.132zM851.642 111.637L634.388 328.72a33.223 33.223 0 0 1-47.132 0c-13.073-13.073-12.9-34.059 0-47.132L804.51 64.505a33.223 33.223 0 0 1 47.132 0c13.073 13.073 12.9 34.06 0 47.132z"
        p-id="2954"
      ></path>
    </svg>
  `,
  styles: [
    `
      svg {
        width: 24px;
        height: 24px;
      }
    `
  ]
})
export class IconComponent {
  @Input() type: string;
}
