import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent, HttpResponse
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Inject, Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {finalize, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {UserService} from '../services/api/user/user.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private router: Router,
    private userService: UserService
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const started = Date.now();
    let ok: string;

    const reqUrl = environment.apiBaseUrl;
    req = req.clone({
      headers: req.headers.set(
        'Authorization',
        'Bearer ' + localStorage.getItem('token')
      )
      , url: reqUrl + '' + req.url,
    });
    return next.handle(req)
      .pipe(
        tap(
          // Succeeds when there is a response; ignore other events
          event => ok = event instanceof HttpResponse ? 'succeeded' : '',
          // Operation failed; error is an HttpErrorResponse
          error => {
            console.log(error);
            if (error.status === 0) {
              this.logout();
            } else {
              ok = 'failed';
            }
          }
        ),
        // Log when response observable either completes or errors
        finalize(() => {
          const elapsed = Date.now() - started;
          const msg = `${req.method} "${req.urlWithParams}"
             ${ok} in ${elapsed} ms.`;
          console.log(msg);
          // this.messenger.add(msg);
        })
      );
  }

  logout(): void {
    this.userService.logout();
    this._document.defaultView.location.reload();
  }
}

