import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef, AfterViewInit
} from '@angular/core';


import videojs from 'video.js';
import * as adapter from 'webrtc-adapter/out/adapter_no_global.js';
import * as RecordRTC from 'recordrtc';

// Required imports
import * as WaveSurfer from 'wavesurfer.js';
import * as MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';
import {AgregarAccionService} from '../../../../services/api/tareas/agregar-accion/agregar-accion.service';
import {ActivatedRoute, Router, Params} from '@angular/router';
import {LocationService} from '../../../../services/api/tareas/geolocalizacion/location.service';
import {StorageService} from '../../../../services/storage.service';
import {AccionesService} from '../../../../services/api/tareas/acciones/acciones.service';
import {formatDate, Location} from '@angular/common';

WaveSurfer.microphone = MicrophonePlugin;

// Register videojs-wavesurfer plugin
import * as Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer.js';


// register videojs-record plugin with this import
import * as Record from 'videojs-record/dist/videojs.record.js';

@Component({
  selector: 'app-audiojs-record',
  templateUrl: './audiojs-record.component.html',
  styleUrls: ['./audiojs-record.component.css']
})
export class AudiojsRecordComponent implements OnInit, OnDestroy, AfterViewInit {

  // reference to the element itself: used to access events and methods
  // tslint:disable-next-line:variable-name
  private _elementRef: ElementRef;

  // index to create unique ID for component
  idx = 'clip1';

  public config: any;
  public player: any;
  public wavesurfer: any;
  public webcamAV: any;
  public plugin: any;
  public isRecord: boolean;
  public isStop: boolean;
  public isPlay: boolean;
  public isPlayPause: boolean;

  public devices: any[] = [];
  public deviceId;


  // constructor initializes our declared vars
  constructor(
    elementRef: ElementRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public accionService: AgregarAccionService,
    public accionesService: AccionesService,
    public locationService: LocationService,
    private storage: StorageService,
    private location: Location
  ) {
    this.player = false;

    // save reference to plugin (so it initializes)
    this.wavesurfer = Wavesurfer;

    // video.js configuration
    this.config = {
      controls: true,
      autoplay: false,
      preload: 'auto',
      fluid: true,
      loop: false,
      bigPlayButton: false,
      controlBar: {
        volumePanel: false,
        fullscreenToggle: false,
        pictureInPictureToggle: false
      },
      plugins: {
        // wavesurfer section is only needed when recording audio-only
        wavesurfer: {
          backend: 'WebAudio',
          waveColor: '#ffffff',
          progressColor: '#ffffff',
          debug: false,
          interact: false,
          cursorWidth: 1,
          displayMilliseconds: false,
          hideScrollbar: false,
          plugins: [
            // enable microphone plugin
            WaveSurfer.microphone.create({
              bufferSize: 4096,
              numberOfInputChannels: 1,
              numberOfOutputChannels: 1,
              constraints: {
                video: false,
                audio: true,
                audioMimeType: 'audio/mp4'
              }
            })
          ]
        },

        // configure videojs-record plugin
        record: {
          audio: true,
          video: false,
          audioMimeType: 'audio/mp4'
        }
      }
    };
  }

  ngOnInit(): void {
  }

  // use ngOnDestroy to detach event handlers and remove the player
  ngOnDestroy(): void {
    if (this.player) {
      console.log('Player dispose');
      this.player.dispose();
      this.player = false;
    }
  }

  ngAfterViewInit(): void {
    const videoPlayer = document.getElementById('audio_' + this.idx);
    // setup the player via the unique element ID
    this.player = videojs(videoPlayer, this.config, () => {

      // print version information at startup
      const msg = 'Using video.js ' + videojs.VERSION +
        ' with videojs-wavesurfer ' + videojs.getPluginVersion('wavesurfer') +
        ' and wavesurfer.js ' + WaveSurfer.VERSION;
      videojs.log(msg);
    });

    // device is ready
    this.player.on('ready', () => {
      this.player.record().getDevice();
      console.log('on ready ok');
    });

    // device is ready
    this.player.on('deviceReady', () => {
      console.log('device is ready!');
    });

    this.player.on('play', event => {
      this.isStop = false;
      this.isPlayPause = false;

      this.isRecord = false;
      this.isPlay = true;
      console.log('Is Play Now!');
    });

    this.player.on('pause', event => {
      this.isStop = false;
      this.isPlayPause = false;

      this.isRecord = false;
      this.isPlay = true;
      console.log('Is Pause/Stop Play Now!');
    });

    this.player.on('stopRecord', event => {
      // recordedData is a blob object containing the recorded data that
      // can be downloaded by the user, stored on server etc.
      this.webcamAV = true;

      this.isStop = true;
      this.isPlayPause = true;

      this.isRecord = false;

      console.log('stop recording');
      setTimeout(() => {
        console.log('stop recording and set multimedia data');
        this.accionesService.multimediaData = this.blobToFile(this.player.recordedData, this.player.recordedData.name);
      }, 500);
    });

    this.player.on('waveReady', event => {
      // this.webcamAV = true;
      // this.isStop = true;
      // this.isRecord = false;
      this.accionesService.multimediaData = this.blobToFile(this.player.recordedData, this.player.recordedData.name);
      // const myBar = document.querySelector('.vjs-control-bar') as HTMLElement;
      // myBar.click();
      console.log(this.accionesService.multimediaData);
      console.log('waveform is ready! HOLA!!!');

    });

    this.player.on('finish', event => {
      console.log('playback finish.');
      this.isStop = true;
      this.isPlayPause = true;

      this.isRecord = false;
      this.isPlay = false;
      console.log('Is finish Play Now!');
    });

    this.player.on('playbackFinish', event => {
      console.log('playback playbackFinish.');
      this.isStop = true;
      this.isPlayPause = true;

      this.isRecord = false;
      this.isPlay = false;
      console.log('Is Stop Play Now!');
    });

    // user clicked the record button and started recording
    this.player.on('startRecord', () => {
      this.isRecord = true;

      this.isStop = false;
      this.isPlayPause = false;

      this.isPlay = false;
      this.isPlayPause = false;
      console.log('started recording!');
    });

    // user completed recording and stream is available
    this.player.on('finishRecord', () => {
      // recordedData is a blob object containing the recorded data that
      // can be downloaded by the user, stored on server etc.
      // document.querySelector('.vjs-control-bar').click();

      this.webcamAV = true;

      this.isStop = true;
      this.isPlayPause = true;

      this.isRecord = false;
      // this.accionesService.multimediaData = this.blobToFile(this.player.recordedData, this.player.recordedData.name);
      console.log('finished recording: TEST');
    });

    // error handling
    this.player.on('error', (element, error) => {
      console.warn(error);
    });

    this.player.on('deviceError', () => {
      console.error('device error:', this.player.deviceErrorCode);
    });
  }

  public blobToFile = (theBlob: Blob, fileName: string): File => {
    return new File([theBlob], fileName, {lastModified: new Date().getTime(), type: theBlob.type});
  }

  playbackStart(): void {
    console.log('Function playbackStart.');

    this.player.wavesurfer().play();

    this.isStop = false;
    this.isPlayPause = false;

    this.isRecord = false;
    this.isPlay = true;
  }

  playbackPause(): void {
    console.log('Function playbackPause.');

    this.player.wavesurfer().pause();

    this.isStop = true;
    this.isPlayPause = true;

    this.isRecord = false;
    this.isPlay = false;
  }
}
