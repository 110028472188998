import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './services/auth/helpers/auth.guard';

/* *Components */
import {LoginComponent} from './components/login/login.component';
import {HomeComponent} from './components/home/home.component';
import {AgregarAccionComponent} from './components/tareas/agregar-accion/agregar-accion.component';
import {MmFotoComponent} from './components/tareas/multimedia/mm-foto/mm-foto.component';
import {MmVideoComponent} from './components/tareas/multimedia/mm-video/mm-video.component';
import {MmAudioComponent} from './components/tareas/multimedia/mm-audio/mm-audio.component';
import {ReportProductsComponent} from './components/tareas/report-products/report-products.component';
import {HistoricTaskComponent} from './components/tareas/historic-task/historic-task.component';
import {LoginGuard} from './services/auth/helpers/login.guard';
import {SignaturePadComponent} from "./components/tareas/multimedia/signature-pad/signature-pad.component";

const routes: Routes = [
  {path: 'home/', redirectTo: '/home/start'},
  {path: 'home/:action', component: HomeComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent, canActivate: [LoginGuard]},
  {path: 'agregar-accion/:idTarea/:tipo', component: AgregarAccionComponent, canActivate: [AuthGuard]},
  {path: 'historic-task', component: HistoricTaskComponent, canActivate: [AuthGuard]},
  {path: 'mm-foto', component: MmFotoComponent, canActivate: [AuthGuard]},
  {path: 'mm-audio', component: MmAudioComponent, canActivate: [AuthGuard]},
  {path: 'mm-video', component: MmVideoComponent, canActivate: [AuthGuard]},
  {path: 'mm-signature', component: SignaturePadComponent, canActivate: [AuthGuard]},
  {path: 'report-stock/:reportarRequerido/:reportarCeros', component: ReportProductsComponent, canActivate: [AuthGuard]},
  {path: '**', redirectTo: '/home/start'},
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

