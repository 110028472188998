<div style="text-align: -webkit-center; margin-top: 20px">
  <a class="navbar-brand" href="#"></a>
</div>
<div class="col-12 login-register-box">

  <div class="login-form-box">
    <div class="welcome-txt text-center">
      <h1 style="color: rgb(37, 54, 79); font-size: 32px">
        TaskTrack
      </h1>
    </div>
    <form [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">
      <div class="form-group" style="padding: 10px;">
        <label for="email">Email</label>
        <input id="email" type="email" formControlName="email" class="form-control"
               [ngClass]="{ 'is-invalid': submitted && myForm.email.errors }" placeholder="Ingresá tu correo aquí"/>
        <div *ngIf="submitted && myForm.email.errors" class="invalid-feedback">
          <div *ngIf="myForm.email.errors.required">Email is required</div>
          <div *ngIf="myForm.email.errors.email">Enter valid email address</div>
        </div>
      </div>
      <div class="form-group" style="padding: 10px;">
        <label for="password">Contraseña</label>
        <input id="password" type="password" formControlName="password" class="form-control"
               [ngClass]="{ 'is-invalid': submitted && myForm.password.errors }" placeholder="Ingresa tu contraseña aquí"/>
        <div *ngIf="submitted && myForm.password.errors" class="invalid-feedback">
          <div *ngIf="myForm.password.errors.required">Password is required</div>
        </div>
      </div>
      <div class="form-group" style="padding: 10px; text-align: center">
        <button [disabled]="loading" class="btn btn-primary btn-accent login">
          <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
          INICIAR
        </button>
      </div>
    </form>
  </div>
</div>

