<app-nav-bar [showBack]="true" ></app-nav-bar>
<!-- icons in miniScreen -->
<div style="right: 5px;margin-top: 5px; position: absolute;">
  <app-icon class="clear" type="clear" (click)="clear()"></app-icon>
  <app-icon class="big" type="big" (click)="fullScreen()"></app-icon>
</div>
<div>
  <ngx-signature-pad #signature [options]="options">
    <!-- icons in fullScreen -->
    <app-icon class="fullscreen-small" type="small" (click)="miniScreen()"></app-icon>
    <div class="clear-icon-container" (click)="clear()">
      <span>Borrar</span>
    </div>
  </ngx-signature-pad>
</div>
<div>
  <form>
    <div class="form-group" style="padding: 10px;">
      <label for="signature-input">Aclaración</label>
      <input id="signature-input" type="text" [(ngModel)]="clarificationSignature" class="form-control" placeholder="Ingrese aquí su aclaración"/>
    </div>
    <div class="row" style="text-align: center; margin-right: 10px;">
      <div class="col-6">
        <button class="btn btn-secondary btn-110" (click)="goToBack();">Cancelar</button>
      </div>
      <div class="col-6">
        <button class="btn btn-primary btn-accent" (click)="sendSignature()">Enviar</button>
      </div>
    </div>
  </form>
</div>
