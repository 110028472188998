<h1 mat-dialog-title>Seleccionar tarea</h1>
<mat-dialog-content>
  <mat-list>
    <mat-list-item *ngFor="let task of tasks" (click)="selectTask(task)">
      <mat-icon mat-list-icon>qr_code</mat-icon>
      <div mat-line>{{task.tipo_check?.nombre}}</div>
      <div mat-line>{{task.empresa_cuenta_local?.cuenta.nombre}} - {{task.empresa_cuenta_local?.descripcion}}</div>
      <div mat-line>{{task?.fecha_programada | pyDate | date: 'dd/MM/yyyy hh:mm a'}}</div>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="accent" (click)="onNoClick()">Salir</button>
</mat-dialog-actions>
