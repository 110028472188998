<h1 mat-dialog-title>QR Scan</h1>
<mat-dialog-content>
  <div style="z-index: 30;position: relative;">
    <zxing-scanner #scanner (scanSuccess)="scanSuccessHandler($event)" [(device)]="currentDevice"
                   (scanError)="handleScanError($event)" (camerasFound)="onCamerasFound($event)"
                   [autofocusEnabled]="true" >
    </zxing-scanner>
  </div>
  <div style="position: absolute;
    bottom: 45vh;
    left: calc(50vw - 20px);
    text-align: center;
    display: inline-flex;
    z-index: 10;">
    <mat-spinner diameter="40" ></mat-spinner>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button color="accent" (click)="onNoClick()">Salir</button>
</mat-dialog-actions>
