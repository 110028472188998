import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs';
import {TaskModel} from '../../../../models';
import {AccionesRespondModel} from '../../../../models/acciones-respond.model';
import {ItemModel, ItemResponseModel, StockGrupoModel} from '../../../../models/stock-grupo.model';

@Injectable({
  providedIn: 'root'
})
export class AccionesService {
  constructor() {
  }

  private _calledFrom: string;
  get calledFrom(): string {
    // console.log('AccionesService return calledFrom ', this._calledFrom);
    return this._calledFrom;
  }

  set calledFrom(value: string) {
    // console.log('AccionesService set calledFrom ', this._calledFrom);
    this._calledFrom = value;
  }

  private _idTarea: number;
  get idTarea(): number {
    // console.log('AccionesService return idTarea ', this._idTarea);
    return this._idTarea;
  }

  set idTarea(value: number) {
    // console.log('AccionesService set idTarea ', this._idTarea);
    this._idTarea = value;
  }

  private _tarea: TaskModel;
  get tarea(): TaskModel {
    // console.log('AccionesService return tarea ', this._tarea);
    return this._tarea;
  }

  set tarea(value: TaskModel) {
    // console.log('AccionesService set tarea ', this._tarea);
    this._tarea = value;
  }

  private _idAccion: number;
  get idAccion(): number {
    // console.log('AccionesService return idAccion ', this._idAccion);
    return this._idAccion;
  }

  set idAccion(value: number) {
    // console.log('AccionesService set idAccion ', this._idAccion);
    this._idAccion = value;
  }

  private _acciones: Array<AccionesRespondModel>;
  get acciones(): Array<AccionesRespondModel> {
    // console.log('AccionesService return acciones ', this._acciones);
    return this._acciones;
  }

  set acciones(value: Array<AccionesRespondModel>) {
    // console.log('AccionesService set acciones ', this._acciones);
    this._acciones = value;
  }

  // tslint:disable-next-line:variable-name
  private _usuario_latitud: string;
  get usuario_latitud(): string {
    // console.log('AccionesService return usuario_latitud ', this._usuario_latitud);
    return this._usuario_latitud;
  }

  set usuario_latitud(value: string) {
    // console.log('AccionesService set usuario_latitud ', this._usuario_latitud);
    this._usuario_latitud = value;
  }

  // tslint:disable-next-line:variable-name
  private _usuario_longitud: string;
  get usuario_longitud(): string {
    // console.log('AccionesService return usuario_longitud ', this._usuario_longitud);
    return this._usuario_longitud;
  }

  set usuario_longitud(value: string) {
    // console.log('AccionesService set usuario_longitud ', this._usuario_longitud);
    this._usuario_longitud = value;
  }

  // tslint:disable-next-line:variable-name
  private _usuario_accuracy: string;
  get usuario_accuracy(): string {
    // console.log('AccionesService return usuario_accuracy ', this._usuario_accuracy);
    return this._usuario_accuracy;
  }

  set usuario_accuracy(value: string) {
    // console.log('AccionesService set usuario_accuracy ', this._usuario_accuracy);
    this._usuario_accuracy = value;
  }

  private _tipoTarea: string;
  get tipoTarea(): string {
    // console.log('AccionesService return tipoTarea ', this._tipoTarea);
    return this._tipoTarea;
  }

  set tipoTarea(value: string) {
    // console.log('AccionesService set tipoTarea ', this._tipoTarea);
    this._tipoTarea = value;
  }

  private _archivo: string;
  get archivo(): string {
    // console.log('AccionesService return archivo ', this._archivo);
    return this._archivo;
  }

  set archivo(value: string) {
    // console.log('AccionesService set archivo ', this._archivo);
    this._archivo = value;
  }

  private _imgB64: string;
  get imgB64(): string {
    // console.log('AccionesService return imgB64 ', this._imgB64);
    return this._imgB64;
  }

  set imgB64(value: string) {
    // console.log('AccionesService set imgB64 ', this._imgB64);
    this._imgB64 = value;
  }

  private _imgUrl: string;
  get imgUrl(): string {
    return this._imgUrl;
  }

  set imgUrl(value: string) {
    this._imgUrl = value;
  }

  private _multimediaData: any = null;
  get multimediaData(): any {
    return this._multimediaData;
  }

  set multimediaData(value: any) {
    this._multimediaData = value;
  }

  private _multimedia = '';
  get multimedia(): string {
    return this._multimedia;
  }

  set multimedia(value: string) {
    this._multimedia = value;
  }

  private _stock: StockGrupoModel;
  get stock(): StockGrupoModel {
    // console.log('AccionesService return stock ', this._stock);
    return this._stock;
  }

  set stock(value: StockGrupoModel) {
    // console.log('AccionesService set stock ', this._stock);
    this._stock = value;
  }

  private _stockReport: ItemResponseModel[];
  get stockReport(): ItemResponseModel[] {
    // console.log('AccionesService return stock ', this._stockReport);
    return this._stockReport;
  }

  set stockReport(value: ItemResponseModel[]) {
    // console.log('AccionesService set stock ', this._stockReport);
    this._stockReport = value;
  }

  public destroy(all = true): void {
    if (all) {
      this._tarea = null;
    }
    this._idTarea = null;
    this._calledFrom = null;
    this._idAccion = null;
    this._acciones = null;
    this._usuario_latitud = null;
    this._usuario_longitud = null;
    this._usuario_accuracy = null;
    this._tipoTarea = null;
    this._archivo = null;
    this._imgB64 = null;
    this._imgUrl = null;
    this._multimediaData = null;
    this._multimedia = null;
    this._stock = null;
    this._stockReport = null;
  }
}
