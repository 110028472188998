<div *ngIf="tasks" style="padding-bottom: 25px">
  <ng-container *ngFor="let tarea of tasks">
    <ng-container *ngIf="tarea.tipo_check && isValidStatus(tarea.id_estado)">
      <mat-card style="margin: 10px 10px 0 10px;">
        <div class="row task-list-row" (click)="showModal(tarea)">
          <div class="col-sm-2 col-md-2 col-lg-2" style="margin-left: 10px; display: none">
            <div *ngIf="tarea.tipo_check?.icon_url">
              <img src="{{tarea.tipo_check.icon_url}}" class="pendiente_imagen"/>
            </div>
            <div *ngIf="!tarea.tipo_check?.icon_url">
              <div class="imagen_relleno"></div>
            </div>
          </div>
          <div class="col-sm-9 col-md-9 col-lg-9 caption">
            <h3>{{tarea.tipo_check?.nombre}}</h3>
            <div class="az-task-icon-text">
              <mat-icon aria-hidden="false" aria-label="Ubicacion">place</mat-icon>
              <span class="az-task-text" *ngIf="tarea.empresa_cuenta_local">
                {{tarea.empresa_cuenta_local?.cuenta.nombre}} - {{tarea.empresa_cuenta_local?.descripcion}}
              </span>
              <span class="az-task-text view-map-link" *ngIf="!tarea.empresa_cuenta_local" (click)="openMap(tarea); $event.stopPropagation()">
                Ver en mapa
              </span>
            </div>
            <div class="az-task-icon-text" style="margin-top: 10px;" *ngIf="tarea.articulo_unidad">
              <mat-icon aria-hidden="false" *ngIf="tarea.movimiento_articulo" aria-label="Item">qr_code</mat-icon>
              <mat-icon aria-hidden="false" *ngIf="!tarea.movimiento_articulo" aria-label="Item">work_outline</mat-icon>
              <span class="az-task-text">
                {{tarea.articulo_unidad?.articulo.descripcion}} - {{tarea.articulo_unidad?.codigo_empresa}}
              </span>
            </div>
            <div class="az-task-icon-text" style="margin-top: 10px;">
              <mat-icon aria-hidden="false" aria-label="Fecha Programada">access_alarm</mat-icon>
              <div class="az-task-text">
                {{tarea?.fecha_programada | pyDate | date: 'dd/MM/yyyy hh:mm a'}}
              </div>
            </div>
            <div class="az-task-icon-text" *ngIf="tarea?.fecha_desde">
              <mat-icon aria-hidden="false" aria-label="Fecha Inicio">schedule</mat-icon>
              <div class="az-task-text">
                <span style="color: #1b1e21">del:</span> {{tarea?.fecha_desde | pyDate | date: 'dd/MM/yyyy hh:mm a'}}<br>
                <span style="color: #1b1e21">al:</span> {{tarea?.fecha_hasta | pyDate | date: 'dd/MM/yyyy hh:mm a'}}
              </div>
            </div>
            <div class="az-task-icon-text" *ngIf="tarea.duracion>0">
              <mat-icon aria-hidden="false" aria-label="Duracion">timelapse</mat-icon>
              <div class="az-task-text">
                {{tarea?.duracion | minutesToDHMS}}
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </ng-container>
  </ng-container>
</div>
