<app-nav-bar
  [showQRScanner]="true"
  [showUpdateTaskList]="true"
  [showCreateTask]="true"
  [showCreateIncident]="true"
  [showRequestLocation]="true"
  (menuClickEvent)="onMenuItemClicked($event)">

</app-nav-bar>
<pull-to-refresh color="#7d4bd5" [disabled]="!pullRefreshActive"></pull-to-refresh>
<mat-tab-group mat-align-tabs="center">
  <mat-tab>
    <ng-template mat-tab-label>
      <div>
        <mat-icon class="tab-icon">date_range</mat-icon>
        <br>
        <span class="label-icon">Pendientes</span>
      </div>
    </ng-template>
    <app-task-list
      [tasks]="pendingTasks"
      [taskSelected]="taskSelected.asObservable()"
      [validStatusArray]="tasksPendingStatus"
      (pullRefreshStatusEvent)="onPullRefreshStatusChange($event)"
      (openQRScanEvent)="onScanQRClick($event)"></app-task-list>

  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div>
        <mat-icon class="tab-icon">playlist_add</mat-icon>
        <br>
        <span class="label-icon">Atendidas</span>
      </div>
    </ng-template>
    <app-task-list
      [tasks]="attendedTasks"
      [taskSelected]="taskSelected.asObservable()"
      [onActionReceived]="taskActionSelected.asObservable()"
      [validStatusArray]="tasksOpenStatus"
      (pullRefreshStatusEvent)="onPullRefreshStatusChange($event)"
      (openQRScanEvent)="onScanQRClick($event)"></app-task-list>

  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <div>
        <mat-icon class="tab-icon">playlist_add_check</mat-icon>
        <br>
        <span class="label-icon">Cerradas</span>
      </div>
    </ng-template>
    <app-task-list
      [tasks]="closedTasks"
      [validStatusArray]="tasksClosedStatus"
      (pullRefreshStatusEvent)="onPullRefreshStatusChange($event)"></app-task-list>

  </mat-tab>
</mat-tab-group>
