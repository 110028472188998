import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lector-qr',
  templateUrl: './lector-qr.component.html',
  styleUrls: ['./lector-qr.component.css']
})
export class LectorQrComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
