import {Component, OnInit} from '@angular/core';
import {Observable, Observer, Subject} from 'rxjs';
import {WebcamImage} from '../webcam/domain/webcam-image';
import {WebcamUtil} from '../webcam/util/webcam.util';
import {WebcamInitError} from '../webcam/domain/webcam-init-error';
import {AgregarAccionService} from '../../../../services/api/tareas/agregar-accion/agregar-accion.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {LocationService} from '../../../../services/api/tareas/geolocalizacion/location.service';
import {StorageService} from '../../../../services/storage.service';
import {AccionesService} from '../../../../services/api/tareas/acciones/acciones.service';
import {Location} from '@angular/common';


@Component({
  selector: 'app-mm-foto',
  templateUrl: './mm-foto.component.html',
  styleUrls: ['./mm-foto.component.css']
})
export class MmFotoComponent implements OnInit {

  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public facingMode = 'environment';
  public errors: WebcamInitError[] = [];

  public base64TrimmedURL: string;
  public base64DefaultURL: string;
  public generatedImage: string;

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  public latitude;
  public longitude;
  public accuracy;
  public location_user;
  public idTarea: number;
  public tipoTarea: string;
  public tarea;


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public accionService: AgregarAccionService,
    public accionesService: AccionesService,
    public locationService: LocationService,
    private storage: StorageService,
    private location: Location
  ) {
  }

  ngOnInit(): void {
    if (this.accionesService.tarea === undefined) {
      this.router.navigate(['/home']);

    } else {
      WebcamUtil.getAvailableVideoInputs()
        .then((mediaDevices: MediaDeviceInfo[]) => {
          this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        });
      this.activatedRoute.params.forEach((params: Params) => {
        this.idTarea = params.idTarea;
        this.tipoTarea = params.tipo;
      });
    }
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
      console.warn('Camera access was not allowed by user!');
    }
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.showWebcam = false;

    console.log('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
    // this.accionService.setFoto(this.webcamImage.imageAsBase64,
    // this.webcamImage.imageAsDataUrl,
    // this.webcamImage.imageData);
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {width: {min: 480, max: 480}, height: {min: 270, max: 270}};

    if (this.facingMode && this.facingMode !== '') {
      result.facingMode = {ideal: this.facingMode};
    }

    return result;
  }

  /** Method that will create Blob and show in new window */
  createBlobImageFileAndShow(): void {
    this.dataURItoBlob(this.base64TrimmedURL).subscribe((blob: Blob) => {
      const imageBlob: Blob = blob;
      const imageName: string = this.generateName();
      const imageFile: File = new File([imageBlob], imageName, {
        type: 'image/jpeg'
      });
      this.generatedImage = window.URL.createObjectURL(imageFile);
      console.log('createBlobImageFileAndShow generatedImage: ', this.generatedImage);
      window.open(this.generatedImage);
    });
  }

  /** Method that will create Blob and save to service */
  createBlobImageFile(): void {
    this.dataURItoBlob(this.base64TrimmedURL).subscribe((blob: Blob) => {
      const imageBlob: Blob = blob;
      const imageName: string = this.generateName();
      const imageFile: File = new File([imageBlob], imageName, {
        type: 'image/jpeg'
      });
      // const  postData = new FormData();
      // postData.append('archivo', imageFile);
      // postData.append('archivo', imageFile);
      this.generatedImage = window.URL.createObjectURL(imageFile);
      console.log('createBlobImageFileAndShow generatedImage: ', this.generatedImage);
      // window.open(this.generatedImage);
      this.accionesService.multimediaData = imageFile;
    });
  }

  /* Method to convert Base64Data Url as Image Blob */
  dataURItoBlob(dataURI: string): Observable<Blob> {
    return Observable.create((observer: Observer<Blob>) => {
      const byteString: string = window.atob(dataURI);
      const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], {type: 'image/jpeg'});
      observer.next(blob);
      observer.complete();
    });
  }

  /** Method to Generate a Name for the Image */
  generateName(): string {
    const date: number = new Date().valueOf();
    let text = '';
    const possibleText =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type like this
    return date + '.' + text + '.jpeg';
  }

  /* Method to create base64Data Url from fetched image */
  getBase64Image(img: HTMLImageElement): string {
    // We create a HTML canvas object that will create a 2d image
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    const dataURL: string = canvas.toDataURL('image/png');
    this.base64DefaultURL = dataURL;
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  }


  /* Method to fetch image from Url */
  getBase64ImageFromURL(url: string): Observable<string> {
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  sendPhoto(): void {
    this.accionesService.calledFrom = 'mm-foto';
    this.accionesService.imgB64 = this.webcamImage.imageAsBase64;
    this.accionesService.imgUrl = this.webcamImage.imageAsDataUrl;
    this.base64TrimmedURL = this.webcamImage.imageAsBase64;
    this.createBlobImageFile();
    // this.accionesService.multimediaData = this.webcamImage.imageData;
    // Obtener fecha hora para concatenar a la descipcion

    this.accionesService.multimedia = JSON.stringify({
      tipo: 'Foto',
      campo: 'archivo'
    });
    this.router.navigate(['/agregar-accion', this.accionesService.idTarea, this.accionesService.tipoTarea]);
  }

  goToBack(): void {
    this.accionesService.destroy();
    // this.location.back();
    this.router.navigate(['/home']);
  }
}
