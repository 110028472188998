import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/database';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireMessaging} from '@angular/fire/messaging';
import {take} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';
import {GLOBAL} from '../global';
import * as webNotification from 'simple-web-notification/web-notification.js';
import {Subject} from 'rxjs';
import {UserService} from '../api/user/user.service';
import {StorageService} from '../storage.service';

@Injectable()
export class MessagingService {

  currentMessage = new BehaviorSubject(null);

  public onMessageReceived: Subject<string>;

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private storageService: StorageService,
    private angularFireMessaging: AngularFireMessaging,
    private userService: UserService) {

    this.onMessageReceived = new Subject<string>();

    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    );
  }

  /**
   * update token in firebase database
   *
   * @param userId userId as a key
   * @param token token as a value
   */
  updateToken(userId, token): void {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(
      () => {
        const data = {};
        data[userId] = token;
        if (this.storageService.getObject(GLOBAL.KEY_CURRENT_USER_FCM_TOKEN, '') !== token) {
          this.userService.updateFirebaseToken(token).subscribe(
            result => {
              this.storageService.setObject(GLOBAL.KEY_CURRENT_USER_FCM_TOKEN, token);
              console.log('Token enviado al servidore: ', result);
            },
            error => {
              console.log('Error al enviar el token al servidor: ', error);
            }
          );
        }
      });
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission(userId): void {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        this.updateToken(userId, token);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * hook method when new notification received in foreground
   */
  receiveMessage(): void {
    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        this.manageNotifications(payload);
        this.currentMessage.next(payload);
      });
  }

  manageNotifications(payload): void {
    try {
      console.log('new message received. ', payload);
      const data = payload.data;
      let type = GLOBAL.FCM_PUSH_GENERIC;

      if (data.tipo_notificacion === 'generico') {
        const receive = JSON.parse(data.dato);
        type = GLOBAL.FCM_PUSH_NEW_TASK_RECEIVE;
        this.showNotification(receive.message, type, false, true);
      } else if (data.tipo_notificacion === 'wakeup') {
        const deviceInfo = this.storageService.getObject(GLOBAL.KEY_DEVICE_INFO, null);
        if (deviceInfo?.isMobile || deviceInfo?.isTablet) {
          this.showNotification('Posición actualizada', type, true, false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  public emitMessageReceived(msg: string): void {
    this.onMessageReceived.next(msg);
  }

  showNotification(message, type = GLOBAL.FCM_PUSH_GENERIC, silentSound = false, emit = false): void {

    webNotification.showNotification('tasktrack', {
      body: message,
      silent: silentSound,
      icon: 'assets/icons/icon-128x128.png',
      onClick: () => {
        console.log('Notification clicked.');
      },
      autoClose: 10000 // auto close the notification after 4 seconds (you can manually close it via hide function)
    }, (error, hide) => {
      if (error) {
        console.error('Unable to show notification: ' + error.message);
      } else {
        console.log('Notification Shown.');
        if (emit) {
          this.emitMessageReceived(type);
        }

        /*setTimeout(() => {
          console.log('Hiding notification....');
          hide(); // manually close the notification (you can skip this if you use the autoClose option)
        }, 10000);*/
      }
    });
  }
}
