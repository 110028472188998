import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

import {GLOBAL} from '../../services/global';
import Swal from 'sweetalert2';
import {UserService} from '../../services/api/user/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private toastr: ToastrService
  ) {

  }

  ngOnInit(): void {
    this.setLoginStyle();

    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });

  }

  ngOnDestroy(): void {
    document.body.style.backgroundImage = 'none';
  }

  setLoginStyle(): void {
    document.body.style.backgroundImage = 'url(\'https://tasktrack.appsolutions.top/assets/img/login-bg1.jpg\')';
    document.body.style.margin = '0';
    document.body.style.padding = '0';
    document.body.style.margin = '0 !important';
    document.body.style.padding = '0 !important';
    document.body.style.backgroundSize = 'cover';
  }

  // for accessing to form fields
  get myForm(): any {
    return this.loginForm.controls;
  }

  onFormSubmit(): void {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.userService.login(this.myForm.email.value, this.myForm.password.value)
      .subscribe(
        data => {
          this.router.navigate(['/']);
        },
        error => {
          // this.toastr.error(error.error.message, 'Login Error al Intentar Iniciar');
          this.loading = false;
          Swal.fire({
            title: 'TaskTrack',
            text: 'Email o contraseña incorrecta.',
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: GLOBAL.color_accent
          }).then((alertResult) => {
            this.router.navigate(['/home']);
          });
        });
  }
}
