import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AccionesModel} from '../../../models/acciones.model';
import {StorageService} from '../../../services/storage.service';
import {AgregarAccionService} from '../../../services/api/tareas/agregar-accion/agregar-accion.service';
import {LocationService} from '../../../services/api/tareas/geolocalizacion/location.service';
import {AccionesService} from '../../../services/api/tareas/acciones/acciones.service';
import Swal from 'sweetalert2';
import {GLOBAL} from '../../../services/global';
import * as moment from 'moment';
import {TaskModel} from '../../../models';
import {AccionesRespondModel} from '../../../models/acciones-respond.model';
import {UtilsService} from '../../../services/utils.service';

declare var $: any;

@Component({
  selector: 'app-agregar-accion',
  templateUrl: './agregar-accion.component.html',
  styleUrls: ['./agregar-accion.component.css']
})
export class AgregarAccionComponent implements OnInit {

  public actions: AccionesModel[];
  public actionsIDs: number[] = [];
  public idTarea: number;
  public tarea: TaskModel;
  public tipoTarea: string;
  private addAttach = false;
  private attachURLsArray = ['mm-foto', 'mm-audio', 'mm-video', 'mm-signature', 'report-stock'];
  public formAttachText = {
    observacion: ''
  };
  public showAttach = {
    picture: true,
    audio: true,
    video: true
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public accionService: AgregarAccionService,
    public accionesService: AccionesService,
    public locationService: LocationService,
    private storageService: StorageService,
    private storage: StorageService,
    private utils: UtilsService,
  ) {

  }

  ngOnInit(): void {
    this.tarea = this.accionesService.tarea;

    this.getActionParams();

    // Verifica de donde viene, si viene de la accion adjuntar enviar los adjuntos al servidor
    const origen = this.accionesService.calledFrom;
    if (this.attachURLsArray.find(e => e === origen) !== undefined) {
      console.log('Se envia datos de ' + origen + ' al API con el metodo saveActionsOnServer');
      this.activatedRoute.queryParams.subscribe(params => {
        this.addAttach = params.attach;
        if (origen === 'report-stock' && this.addAttach) {
          this.showAttachMenuModal();
        } else {
          this.saveActionOnServer();
        }
      });
    }

    this.manageAttach();
  }

  getActionParams(): void {
    this.activatedRoute.params.forEach((params: Params) => {
      if (this.tarea === undefined) {
        this.router.navigate(['/home']);
      } else {
        this.setComponentParams(params.idTarea, params.tipo);
      }
    });
  }

  setComponentParams(idTarea, tipoTarea): void {
    this.idTarea = idTarea;
    this.tipoTarea = tipoTarea;

    const response = this.storage.getObject(GLOBAL.KEY_COMPANY_CONFIG, null);
    const tipo = this.getTipoTarea(this.tipoTarea);
    if (this.idTarea > 0) {
      response[tipo].forEach(value => {
        if (value.id_tipo_check === this.tarea.id_tipo_check) {
          this.actions = [value];
        }
      });
    } else {
      this.actions = response[tipo];
    }
    console.log('Actions: ', this.actions);
  }

  manageAttach(): void {
    const deviceInfo = this.storageService.getObject(GLOBAL.KEY_DEVICE_INFO, null);
    const browserVersionAux = deviceInfo.browser_version.split('.', 2);
    const browserVersion: number = Number(browserVersionAux[0] + '.' + browserVersionAux[1]);

    if (deviceInfo.os.toLowerCase() === 'ios') {
      this.showAttach = {
        picture: true,
        audio: false,
        video: false
      };

      if (deviceInfo.browser.toLowerCase() === 'safari' && browserVersion >= 14) {
        this.showAttach = {
          picture: true,
          audio: true,
          video: true
        };
      }
    }
  }

  getTipoTarea(tipo: string): string {
    if (tipo === 'INCIDENTE') {
      tipo = 'acciones_incidentes';
    } else {
      tipo = 'acciones';
    }
    return tipo;
  }

  onActionClick(selectedAction): void {
    console.log('selectedAction', selectedAction);

    if (this.tarea.id_tipo_check === 0) {
      this.tarea.id_tipo_check = selectedAction.id_tipo_check;
    }

    this.saveActionID(selectedAction.id);
    if (selectedAction.acciones.length > 0) {
      this.actions = selectedAction.acciones;

    } else {
      // Primero intenta obtener la posicion del usuario antes de avanzar con el reporte de tareas
      this.locationService.getPositionIsPermissionGranted(true).then(pos => {
        const userLocation = {
          latitude: pos.latitude,
          longitude: pos.longitude,
          accuracy: pos.accuracy
        };

        this.storageService.setObject(GLOBAL.KEY_CURRENT_USER_LOCATION, userLocation);
        console.log('User location = ', userLocation);

        // Se avanza con el reporte al tener la posicion del usuario
        this.manageActionClick(selectedAction);

      }).catch(error => {
        console.log('Location error: ', error);
        // En caso de no obtener la posicion igual se avanza con el reporte
        this.manageActionClick(selectedAction);
      });
    }
  }

  manageActionClick(selectedAction): void {
    let reportProduct = false;

    try {
      if (selectedAction.stock_grupo !== null &&
        selectedAction.stock_grupo.items !== null && selectedAction.stock_grupo.items.length > 0) {
        reportProduct = true;
      }
    } catch (e) {
    }

    if (reportProduct) {
      this.reportProduct(selectedAction);
    } else {
      this.showAttachMenuModal();
    }
  }

  reportProduct(selectedAction): void {
    this.accionesService.calledFrom = 'agregar-accion';
    this.accionesService.stock = selectedAction.stock_grupo;
    this.saveActionParam(this.actionsIDs, '');

    this.router.navigate(['/report-stock', selectedAction.stock_requerido, selectedAction.stock_reportar_ceros]);
  }

  showAttachMenuModal(): void {
    $('#opcionesMultimediaModal').modal('show');
  }

  onClickAttachMenuItem(opcion): void {
    console.log(opcion);
    $('#opcionesMultimediaModal').modal('hide');

    if (opcion === 'mm-texto') {
      $('#opcionesTextoModal').modal('show');

    } else {
      // Ya no se procesan las acciones porque ya fueron procesadas
      if (!this.addAttach) {
        this.saveActionParam(this.actionsIDs);
      }
      this.accionesService.calledFrom = 'agregar-accion';

      if (opcion === 'mm-foto') {
        this.router.navigate(['/mm-foto']);
      } else if (opcion === 'mm-audio') {
        this.router.navigate(['/mm-audio']);
      } else if (opcion === 'mm-video') {
        this.router.navigate(['/mm-video']);
      } else if (opcion === 'mm-signature') {
        this.router.navigate(['/mm-signature']);
      }
    }
  }

  onActionAttachText(observacion): void {
    console.log('onActionAttachText: ', observacion, this.actionsIDs);
    $('#opcionesTextoModal').modal('hide');
    if (this.addAttach) {
      this.accionesService.acciones.forEach(action => {
        action.descripcion = observacion;
      });
    } else {
      this.saveActionParam(this.actionsIDs, observacion);
    }
    this.saveActionOnServer();
  }

  saveActionID(actionId: number): void {
    this.actionsIDs[this.actionsIDs.length] = actionId;
  }

  /*
  * Esta funcion guarda lo necesario en el service para luego enviar al servidor
  * */
  saveActionParam(actionIDs: number[], actionObservation: string = ''): void {
    console.log('saveActions recibe: ', actionIDs, actionObservation);

    const userLocation = this.storageService.getObject(GLOBAL.KEY_CURRENT_USER_LOCATION, null);
    console.log('User location saved = ', userLocation);

    const deviceData = this.storageService.getObject(GLOBAL.KEY_DEVICE_INFO, null);

    const dateTime = moment(new Date());

    const auxAccArr: Array<AccionesRespondModel> = [];
    const grupo_en_app_id = this.utils.makeRandomString(10);
    actionIDs.forEach(value => {
      auxAccArr[auxAccArr.length] = {
        id: 0,
        descripcion: actionObservation,
        id_accion: value,
        fecha: dateTime.format(GLOBAL.format_date_time),
        latitud: userLocation !== null ? userLocation.latitude : 0,
        longitud: userLocation !== null ? userLocation.longitude : 0,
        accuracy: userLocation !== null ? userLocation.accuracy : 0,
        device_data: deviceData !== null ? JSON.stringify(deviceData) : {},
        grupo_en_app: grupo_en_app_id,
        multimedia: null,
        stock: null
      };
    });

    console.log('auxAccArr', auxAccArr);

    this.accionesService.acciones = auxAccArr;

    this.accionesService.tipoTarea = this.tipoTarea;
  }

  saveActionOnServer(): void {
    console.log('saveActionsOnServer params: ',
      this.accionesService.idTarea,
      this.accionesService.tarea,
      this.accionesService.acciones,
      this.accionesService.tipoTarea,
      this.accionesService.multimedia,
      this.accionesService.multimediaData,
      this.accionesService.stockReport);

    this.accionService.reportAction(
      this.accionesService.idTarea,
      this.accionesService.tarea,
      this.accionesService.acciones,
      this.accionesService.tipoTarea,
      this.accionesService.multimedia,
      this.accionesService.multimediaData,
      this.accionesService.stockReport)
      .then(
        result => { // Success

          Swal.fire({
            title: 'TaskTrack',
            text: 'Proceso realizado con éxito.\nDesea realizar otro reporte sobre la misma tarea?',
            icon: 'success',
            confirmButtonText: 'Si',
            confirmButtonColor: GLOBAL.color_accent,
            showCancelButton: true,
            cancelButtonColor:  GLOBAL.color_accent,
            cancelButtonText: 'No'
          }).then((alertResult) => {
            // Destruye toda la info de la tarea reportada.
            this.accionesService.destroy(false);

            console.log('Server result', result);

            // Guarda la tarea actualizada del servidor para luego actualizar en la cache de la app
            if (result !== undefined && result !== null) {
              this.tarea = result;
              this.accionesService.tarea = result;
            }

            if (alertResult.isConfirmed) {
              // Si el usuario quiere subir una nueva tarea redirige nuevamente a agregar-accion
              this.tarea = this.accionesService.tarea;
              this.accionesService.calledFrom = 'task-list';
              this.accionesService.idTarea = this.tarea.id;
              this.accionesService.tarea = this.tarea;

              this.setComponentParams(this.tarea.id, this.tarea.tipo_check.tipo_accion);
            } else {
              // Redirige al home
              this.router.navigate(['/home/updateTask']);
            }
          });
        },
        result => { // Error
          console.log('agregar-accion reportAction result error ', result);
          Swal.fire({
            title: 'TaskTrack',
            text: GLOBAL.text_default_error_msg,
            icon: 'error',
            confirmButtonText: 'Aceptar',
            confirmButtonColor: GLOBAL.color_accent
          }).then((alertResult) => {
            this.router.navigate(['/home']);
          });
        }
      );
  }
}
