<app-nav-bar [showBack]="true"></app-nav-bar>
<div class="row" style="margin: 1px;">
  <ng-container *ngFor="let action of actions">
    <ng-container *ngIf="action.activo === 1 && action.borrado === 0">
      <div class="col-4 col-sm-4 col-md-4 col-lg-3" style="text-align: -webkit-center ; padding: 0px; margin-top: 10px;">
        <div class="border-light">
          <div class="align-middle" style="margin-top: 20px;" (click)="onActionClick(action)">
            <div style="min-height: 120px;border-bottom: 1px solid;">
              <div class="imagen_container" *ngIf="action.url_icono">
                <img src="{{action.url_icono}}" class="pendiente_imagen" alt=""/>
              </div>
              <div class="imagen_container" *ngIf="!action.url_icono">
                <img src="assets/images/az_task.png" class="pendiente_imagen" alt=""/>
              </div>
            </div>
            <div class="">
              <p style="margin: 0!important;padding: 2px 2px 2px;">{{action.accion}}</p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>

<!-- Modal -->
<div class="modal fade" id="opcionesMultimediaModal" tabindex="-1" role="dialog"
     aria-labelledby="opcionesMultimediaModalTitle"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="exampleModalLongTitle">Adjuntar</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row" style="text-align: center;">
            <div class="col-6 col-sm-4 col-md-4 col-lg-3" (click)="onClickAttachMenuItem('mm-texto')">
              <div class="imagen_container_modal">
                <img src="assets/icons/ic_action_texto.png"/>
              </div>
              <div><p>Texto</p></div>
            </div>
            <div *ngIf="showAttach.picture" class="col-6 col-sm-4 col-md-4 col-lg-3" (click)="onClickAttachMenuItem('mm-foto')">
              <div class="imagen_container_modal">
                <img src="assets/icons/ic_action_foto.png"/>
              </div>
              <div><p>Foto</p></div>
            </div>
            <div *ngIf="showAttach.audio" class="col-6 col-sm-4 col-md-4 col-lg-3" (click)="onClickAttachMenuItem('mm-audio')">
              <div class="imagen_container_modal">
                <img src="assets/icons/ic_action_audio.png"/>
              </div>
              <div><p>Audio</p></div>
            </div>
            <div *ngIf="showAttach.video" class="col-6 col-sm-4 col-md-4 col-lg-3" (click)="onClickAttachMenuItem('mm-video')">
              <div class="imagen_container_modal">
                <img src="assets/icons/ic_action_video.png"/>
              </div>
              <div><p>Video</p></div>
            </div>
            <div *ngIf="showAttach.picture" class="col-6 col-sm-4 col-md-4 col-lg-3" (click)="onClickAttachMenuItem('mm-signature')">
              <div class="imagen_container_modal">
                <img src="assets/icons/signature_with_a_pen.png"/>
              </div>
              <div><p>Firma</p></div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-accent" data-dismiss="modal" (click)="onActionAttachText('');">Reportar sin adjunto</button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="opcionesTextoModal" tabindex="-1" role="dialog"
     aria-labelledby="opcionesTextoModalTitle"
     aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="ModalLongTitle">Adjuntar texto</h3>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row" style="text-align: center;">
            <div class="input-group mb-3">
              <textarea
                type="text" class="form-control"
                [(ngModel)]="formAttachText.observacion"
                placeholder="Observaci&oacute;n"
                aria-label="Observacion"
                aria-describedby="basic-addon2"></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-accent" data-dismiss="modal"
                (click)="onActionAttachText(formAttachText.observacion);">Enviar
        </button>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
      </div>
    </div>
  </div>
</div>




