import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() {
  }

  setString(key, value): void {
    localStorage.setItem(key, value);
  }

  getString(key, defaultValue): any {
    const val = localStorage.getItem(key);
    if (!val && defaultValue) {
      return defaultValue;
    } else {
      return val;
    }
  }

  setObject(key, value): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getObject(key, defaultValue = null): any {
    const val = JSON.parse(localStorage.getItem(key));
    if (!val) {
      return defaultValue;
    } else {
      return val;
    }
  }

  removeItem(key): void {
    localStorage.removeItem(key);
  }

  clear(key): void {
    localStorage.clear();
  }
}
