<video id="video_{{idx}}" class="video-js vjs-default-skin" playsinline style="background: black; height: calc(100vh - 220px); position: fixed; top: 0; overflow: hidden"></video>
<div class="camera-switch" *ngIf="devices.length > 1" (click)="changeDevice()"></div>

<div class="form-group" style="padding: 10px;position: fixed;bottom: 20px; background: white;width: 100%; height: 100px; display: none">
  <div class="container">
    <div class="" style="text-align: center;">
      <div class="col-xs-6 col-sm-4 col-md-4 col-lg-3">
        <a id="start" *ngIf="!isRecord"  (click)="player.record().start()">
          <img src="/assets/icons/record-circle.svg" alt="Bootstrap" width="50" height="50">
        </a>
        <a id="stop" *ngIf="isRecord" (click)="player.record().stop()">
          <img src="/assets/icons/stop-circle.svg" alt="Bootstrap" width="50" height="50">
        </a>
        <a style="margin-left: 15px" id="play" *ngIf="isStop || isPlayPause" (click)="playbackStart();">
          <img src="/assets/icons/play-circle.svg" alt="Bootstrap" width="50" height="50">
        </a>
        <a style="margin-left: 15px" id="pause" *ngIf="isPlay" (click)="playbackPause();">
          <img src="/assets/icons/stop-circle-fill.svg" alt="Bootstrap" width="50" height="50">
        </a>
      </div>
    </div>
  </div>
</div>


