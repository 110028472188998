import {Component, OnInit} from '@angular/core';
import {AccionesService} from '../../../services/api/tareas/acciones/acciones.service';
import {Router} from '@angular/router';
import {ReportedTaskModel, TaskModel} from '../../../models';
import {GLOBAL} from '../../../services/global';
import {StorageService} from '../../../services/storage.service';
import {AccionesModel} from '../../../models/acciones.model';

declare var $: any;

@Component({
  selector: 'app-historic-task',
  templateUrl: './historic-task.component.html',
  styleUrls: ['./historic-task.component.css']
})
export class HistoricTaskComponent implements OnInit {

  public checkBoxTypeID = 17;
  public taskStatusCantEditProducts = 2;

  public task: TaskModel;
  public reportedTasks: ReportedTaskModel[] = [];
  public selectedReportedTask: ReportedTaskModel;

  constructor(
    private router: Router,
    private storage: StorageService,
    public accionesService: AccionesService
  ) {
  }

  ngOnInit(): void {
    if (this.accionesService.tarea === undefined) {
      this.router.navigate(['/home']);
    } else {
      this.task = this.accionesService.tarea;
      this.reportedTasks = this.task.acciones_reportadas;
    }
  }

  openMap(selectedReportedTask): void {
    console.log(selectedReportedTask);
    window.open('https://maps.google.com/maps?saddr=' + selectedReportedTask.usuario_latitud + ',' + selectedReportedTask.usuario_longitud +
      '&daddr=' + selectedReportedTask.usuario_latitud + ',' + selectedReportedTask.usuario_longitud, '_blank');
  }

  viewObservations(selectedReportedTask): void {
    this.selectedReportedTask = selectedReportedTask;
    $('#historicObservationsModal').modal({
      backdrop: 'static'
    });
  }

  viewItems(selectedReportedTask): void {
    this.selectedReportedTask = selectedReportedTask;
    $('#historicItemsModal').modal({
      backdrop: 'static'
    });
  }

  updateStock(selectedReportedTask): void {
    const config = this.storage.getObject(GLOBAL.KEY_COMPANY_CONFIG, null);
    const selectedReportedTaskIds = selectedReportedTask.id_acciones_reportadas_en_grupo.split(',');

    let parentActions = config.acciones;
    let childActions = [];
    let stockGroup = null;
    let reportCero = 0;
    let requiredStock = 0;
    selectedReportedTaskIds.forEach(actionID => {
      const numActionID = Number(actionID.trim());
      if (childActions.length === 0) {
        parentActions = parentActions.filter(action => action.id === numActionID);
      } else {
        parentActions = childActions.filter(action => action.id === numActionID);
      }
      childActions = parentActions[0].acciones;
      stockGroup = parentActions[0].stock_grupo;
      reportCero = parentActions[0].stock_reportar_ceros;
      requiredStock = parentActions[0].stock_requerido;
    });

    selectedReportedTask.checks_acciones_stocks.forEach(reportedItem => {
      stockGroup.items.forEach(item => {
        if (reportedItem.id_item === item.id) {
          item.valor = reportedItem.cantidad;
        }
      });
    });

    this.accionesService.stock = stockGroup;
    this.router.navigate(['/report-stock', requiredStock, reportCero], {
      queryParams: {
        updateStock: true,
        reportedActionID: selectedReportedTask.id
      }
    });
  }
}
