<h1 mat-dialog-title>Opciones</h1>
<mat-dialog-content>
  <div class="row" style="text-align: center;">
    <div *ngIf="tarea?.id_estado < 3 && tarea?.tipo_accion !== 'INCIDENTE'" class="col-12" (click)="onClickMenu('agregar-accion')">
      <div class="imagen_container_modal">
        <img src="assets/icons/ic_add_action.png"/>
      </div>
      <div><p>Agregar Accion</p></div>
    </div>
    <div class="col-12" (click)="onClickMenu('informacion')">
      <div class="imagen_container_modal">
        <img src="assets/icons/ic_info.png"/>
      </div>
      <div><p>Informacion</p></div>
    </div>
    <div *ngIf="tarea?.id_estado > 1" class="col-12" (click)="onClickMenu('historial')">
      <div class="imagen_container_modal">
        <img src="assets/icons/ic_actions_list.png"/>
      </div>
      <div><p>Ver Historial</p></div>
    </div>
    <div *ngIf="tarea?.id_estado === 2 && tarea?.tipo_accion !== 'INCIDENTE'" class="col-12" (click)="onClickMenu('terminar')">
      <div class="imagen_container_modal">
        <img src="assets/icons/ic_close_task.png"/>
      </div>
      <div><p>Terminar Tarea</p></div>
    </div>
    <div *ngIf="tarea?.id_estado === 1" class="col-12" (click)="onClickMenu('cancelar')">
      <div class="imagen_container_modal">
        <img src="assets/icons/ic_cancel_button.png"/>
      </div>
      <div><p>Cancelar Tarea</p></div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="onNoClick()">Salir</button>
</mat-dialog-actions>
