import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import 'rxjs/add/operator/map';
import {GLOBAL} from '../../global';
import {ServerResponseModel} from '../../../models/server-response.model';
import {TaskModel} from '../../../models';

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  public url: string;

  constructor(
    public http: HttpClient
  ) {
    this.url = GLOBAL.url;
  }

  public async updateTaskStatus(task_id, status_id): Promise<any> {
    const params = new FormData();
    params.append('app_type', 'azentry');
    params.append('id', task_id);
    params.append('id_estado', status_id);

    const result = await this.http.post(this.url + 'sync/checks', params)
      .toPromise()
      .catch(errorResponse => {
        console.log('TasksService | updateTaskStatus | errorResponse', errorResponse);
        return new Promise((resolve, reject) => {
          reject(errorResponse.message);
        });
      });

    const resp: ServerResponseModel = result as ServerResponseModel;
    console.log('TasksService | updateTaskStatus | resp', resp);

    if (resp.data != null) {
      return new Promise((resolve, reject) => {
        resolve(resp.data);
      });
    }
  }

  public async updateTask(task): Promise<any> {
    const params = new FormData();
    params.append('app_type', 'azentry');
    params.append('id', String(task.id));
    params.append('id_estado', String(task.id_estado));

    if (task.articulo_unidad !== null && task.articulo_unidad.codigo_qr !== null) {
      params.append('codigo_qr', task.articulo_unidad.codigo_qr);
    }

    const actions = JSON.stringify(task.acciones_reportadas);
    params.append('acciones', actions);

    console.log(actions);

    const result = await this.http.post(this.url + 'sync/checks', params)
      .toPromise()
      .catch(errorResponse => {
        console.log('TasksService | updateTask | errorResponse', errorResponse);
        return new Promise((resolve, reject) => {
          reject(errorResponse.message);
        });
      });

    const resp: ServerResponseModel = result as ServerResponseModel;
    console.log('TasksService | updateTask | resp', resp);

    if (resp.data != null) {
      return new Promise((resolve, reject) => {
        resolve(resp.data);
      });
    }
  }

  getSyncTasks(fecha = null, requestUrl = null): any {
    if (requestUrl === null) {
      let paramFecha = '';
      if (fecha !== null) {
        paramFecha = '?ultima_sync=' + fecha;
      }

      requestUrl = this.url + 'sync/checks' + paramFecha;
    }

    // return this.http.get<any>(this.url + 'sync/checks?dias=20' + paramFecha).map(res => res.valueOf());
    return this.http.get<any>(requestUrl).map(res => res.valueOf());
  }

  public updateTaskOnList(tasks, updatedTask): any {
    if (updatedTask !== null && updatedTask !== undefined) {

      let updateTask = false;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < tasks.length; i++) {
        if (tasks[i].id === updatedTask.id) {
          updateTask = true;
          tasks[i].acciones_reportadas = updatedTask.acciones_reportadas;
          tasks[i].fecha_desde = updatedTask.fecha_desde;
          tasks[i].fecha_hasta = updatedTask.fecha_hasta;
          tasks[i].duracion = updatedTask.duracion;
          tasks[i].id_estado = updatedTask.id_estado;
          break;
        }
      }

      if (!updateTask) {
        tasks[tasks.length] = updatedTask;
      }

      return tasks;
    }
  }

  public shortTask(tasks: TaskModel[], type = 'desc', column = 'fecha_hasta'): TaskModel[] {
    if (column === 'fecha_hasta') {
      if (type === 'desc') {
        tasks.sort((a, b) => {
          if (a.fecha_hasta < a.fecha_hasta) {
            return 1;
          }
          if (a.fecha_hasta > b.fecha_hasta) {
            return -1;
          }
          return 0;
        });
      } else {
        tasks.sort((a, b) => {
          if (a.fecha_hasta > a.fecha_hasta) {
            return 1;
          }
          if (a.fecha_hasta < b.fecha_hasta) {
            return -1;
          }
          return 0;
        });
      }
    } else if (column === 'fecha_programada') {
      if (type === 'desc') {
        tasks.sort((a, b) => {
          if (a.fecha_programada < a.fecha_programada) {
            return 1;
          }
          if (a.fecha_programada > b.fecha_programada) {
            return -1;
          }
          return 0;
        });
      } else {
        tasks.sort((a, b) => {
          if (a.fecha_programada > a.fecha_programada) {
            return 1;
          }
          if (a.fecha_programada < b.fecha_programada) {
            return -1;
          }
          return 0;
        });
      }
    }
    return tasks;
  }
}
