import {Component, OnInit, ViewChild} from '@angular/core';
import {NgxSignatureOptions, NgxSignaturePadComponent} from '@eve-sama/ngx-signature-pad';
import {ActivatedRoute, Router} from '@angular/router';
import {AccionesService} from '../../../../services/api/tareas/acciones/acciones.service';
import Swal from 'sweetalert2';
import {GLOBAL} from "../../../../services/global";
import {UtilsService} from "../../../../services/utils.service";

@Component({
  selector: 'app-mobile',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.css']
})
export class SignaturePadComponent implements OnInit {
  @ViewChild('signature') signature: NgxSignaturePadComponent;
  public clarificationSignature = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public accionesService: AccionesService,
    public utils: UtilsService,
  ) {
  }

  public options: NgxSignatureOptions = {
    backgroundColor: '#F4F5F5',
    width: 0,
    height: 0,
    css: {
      'border-radius': '4px'
    }
  };

  ngOnInit(): void {
    if (this.accionesService.tarea === undefined) {
      this.router.navigate(['/home']);
    }
    this.calcMiniscreenSize();
  }

  public sendSignature(): void {
    if (this.signature.isEmpty()) {
      Swal.fire({
        title: 'La firma es requerida',
        icon: 'warning',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: GLOBAL.color_accent
      });
    } else if ((!this.clarificationSignature || this.clarificationSignature.trim().length === 0)) {
      Swal.fire({
        title: 'La aclaración de la firma es requerida',
        icon: 'warning',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: GLOBAL.color_accent
      });
    } else {
      const imgType = 'image/jpeg';
      const base64UriEncodeArray = this.signature.toDataURL(imgType).split(',');
      this.accionesService.calledFrom = 'mm-signature';
      this.accionesService.imgUrl = this.signature.toDataURL(imgType);
      this.accionesService.multimediaData = this.utils.createBlobImageFileFromBase64(base64UriEncodeArray[1], imgType);
      this.accionesService.multimedia = JSON.stringify({
        tipo: 'Firma',
        aclaracion_firma: this.clarificationSignature,
        descripcion: '',
        campo: 'archivo'
      });
      this.router.navigate(['/agregar-accion', this.accionesService.idTarea, this.accionesService.tipoTarea]);
    }
  }

  goToBack(): void {
    this.accionesService.destroy();
    this.router.navigate(['/home']);
  }

  public clear(): void {
    this.signature.clear();
  }

  public fullScreen(): void {
    this.signature.fullScreen();
  }

  public miniScreen(): void {
    this.signature.miniScreen();
  }

  private calcMiniscreenSize(): void {
    const width = document.querySelector('body').clientWidth;
    const height = (width * 2) / 3;
    this.options.width = width;
    this.options.height = height;
  }
}
