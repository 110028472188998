import {Injectable} from '@angular/core';
import {GLOBAL} from '../../../global';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor() {
  }

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position: Position) => {
            resolve({latitude: position.coords.latitude, longitude: position.coords.longitude, accuracy: position.coords.accuracy});
          },
          err => {
            reject(err);
          });
      } else {
        reject({
          code: 0,
          message: 'Not supported'
        });
      }
    });
  }

  getPositionIsPermissionGranted(force = false): Promise<any> {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation && navigator.permissions) {
        navigator.permissions.query({name: 'geolocation'})
          .then(permissions => {
            if (permissions.state === 'granted') {
              navigator.geolocation.getCurrentPosition((position: Position) => {
                  resolve({latitude: position.coords.latitude, longitude: position.coords.longitude, accuracy: position.coords.accuracy});
                },
                err => {
                  reject(err);
                });
            } else {
              reject({
                code: 1,
                message: 'No permission granted'
              });
            }
          });
      } else if (force) {
        navigator.geolocation.getCurrentPosition((position: Position) => {
            resolve({latitude: position.coords.latitude, longitude: position.coords.longitude, accuracy: position.coords.accuracy});
          },
          err => {
            reject(err);
          });
      } else {
        reject({
          code: 0,
          message: 'Not supported'
        });
      }
    });
  }
}
