import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/map';
// import  { Acciones } from '../../../../models/acciones.model';
import {AccionesArticuloModel} from '../../../../models/acciones-articulo.model';
import {GLOBAL} from '../../../global';
import {StorageService} from '../../../storage.service';
import {Visitas} from '../../../../models/visitas-reportar.models';
import {ServerResponseModel} from '../../../../models/server-response.model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class AgregarAccionService {
  public url: string;

  constructor(
    public httpClient: HttpClient
  ) {
    this.url = GLOBAL.url;
  }


  public async reportAction(
    id_tarea,
    tarea,
    acciones,
    tipoTarea,
    multimedia = null,
    archivo = null,
    stock = null): Promise<any> {

    console.log('reportAction id_tarea: ', id_tarea);
    console.log('reportAction tarea: ', tarea);
    console.log('reportAction tipoTarea: ', tipoTarea);
    console.log('reportAction multimedia: ', multimedia);
    console.log('reportAction archivo: ', archivo);
    console.log('reportAction stock: ', stock);

    const params = new FormData();
    params.append('app_type', 'azentry');

    params.append('id_estado', GLOBAL.TASK_STATUS_ATTENDED);

    if (tarea.articulo_unidad !== null && tarea.articulo_unidad.codigo_qr !== null) {
      params.append('codigo_qr', tarea.articulo_unidad.codigo_qr);
    }

    if (archivo !== null) {
      params.append('archivo', archivo);
    }

    for (const accion of tarea.acciones_reportadas) {
      accion.fecha = accion.fecha_hora;
      accion.latitud = accion.usuario_latitud;
      accion.longitud = accion.usuario_longitud;
      accion.accuracy = accion.usuario_accuracy;
    }

    if (multimedia !== null && multimedia !== '' && acciones.length > 0) {
      try {
        // Se agrega el adjunto a la primera accion
        acciones[0].multimedia = JSON.parse(multimedia);
      } catch (e) {
        console.log(e);
      }
    }

    const dateTime = moment(new Date());
    let fechaProgramada = dateTime.format(GLOBAL.format_date_time);

    if (stock !== null && stock.length > 0 && acciones.length > 0) {
      try {
        // Se agrega los datos del stock a la ultima accion
        acciones[acciones.length - 1].stock = stock;
      } catch (e) {
        console.log(e);
      }
    }

    if (tarea.acciones_reportadas.length > 0) {
      fechaProgramada = tarea.acciones_reportadas[0].fecha;
      acciones.forEach(value => {
        tarea.acciones_reportadas[tarea.acciones_reportadas.length] = value;
      });
      acciones = tarea.acciones_reportadas;
    }

    acciones = JSON.stringify(acciones);
    params.append('acciones', acciones);

    if (id_tarea !== 0) {
      params.append('id', id_tarea);
    } else {
      params.append('fecha', fechaProgramada);
      params.append('id_tipo_check', tarea.id_tipo_check);
    }

    console.log('reportAction acciones: ', acciones);

    const result = await this.httpClient.post(this.url + 'sync/checks', params)
      .toPromise()
      .catch(errorResponse => {
        console.log('AgregarAccionService | reportAction | errorResponse', errorResponse);
        return new Promise((resolve, reject) => {
          reject(errorResponse.message);
        });
      });

    const resp: ServerResponseModel = result as ServerResponseModel;
    console.log('AgregarAccionService | reportAction | resp', resp);

    if (resp.data != null) {
      return new Promise((resolve, reject) => {
        resolve(resp.data);
      });
    }
  }
}
