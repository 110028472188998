import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {User} from '../../../models';
import {ServerResponseModel} from '../../../models/server-response.model';
import {ConfEmpresaModel, ConfiguracionEmpresaModel} from '../../../models/conf-empresa.model';
import {environment as env} from '../../../../environments/environment';
import {LocationService} from '../tareas/geolocalizacion/location.service';
import {GLOBAL} from '../../global';
import {StorageService} from '../../storage.service';

@Injectable({providedIn: 'root'})
export class UserService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public latitud_login: string;
  public longitud_login: string;

  constructor(
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string): any {
    // return this.http.post<any>(`auth/login`, { email, password })
    return this.http.post<any>(`login`, {email, password})
      .pipe(map(user => {
        if (user && user.data.token) {

          // store user details in local storage to keep user logged in

          localStorage.setItem('currentUser', JSON.stringify(user.data.user));

          localStorage.setItem('token', user.data.token);
          this.currentUserSubject.next(user);
        }

        return user;
      }));
  }

  logout(): void {
    // remove user data from local storage for log out
    this.storageService.removeItem(GLOBAL.KEY_COMPANY_CONFIG);
    this.storageService.removeItem(GLOBAL.KEY_LAST_TASK_LIST);
    this.storageService.removeItem(GLOBAL.KEY_LAST_SYNC_SERVER);
    this.storageService.removeItem(GLOBAL.KEY_CURRENT_USER);
    this.storageService.removeItem(GLOBAL.KEY_CURRENT_USER_FCM_TOKEN);

    this.currentUserSubject.next(null);
    window.location.reload();
  }

  public updateFirebaseToken(token): any {
    const params = new FormData();
    params.append('token', token);
    return this.http.post('notificaciones/suscribir', params).map(res => res.valueOf());
  }

  public getConfEmpresa(): any {
    return this.http.get<any>('empresa/configuraciones').map(res => res.valueOf());
  }

  public async getConfEmpresaOld(): Promise<any> {
    const result = await this.http.get('empresa/configuraciones').toPromise();
    const resp: ServerResponseModel = result as ServerResponseModel;
    const ConfEmpresa: Array<ConfEmpresaModel> = resp.data as Array<ConfEmpresaModel>;

    if (ConfEmpresa != null) {
      localStorage.setItem(GLOBAL.KEY_COMPANY_CONFIG, JSON.stringify(ConfEmpresa));

    } else {
      console.log('Configuracion de empresa no retorno ningun valor');
    }
    /*
      if (categoriesCourses != null) {
        return new Promise((resolve, reject) => {
          resolve(categoriesCourses);
        });
      } else {
        return new Promise((resolve, reject) => {
          reject(resp.message);
        });
      }*/
  }
}
