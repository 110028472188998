import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() {
  }

  public makeRandomString(length): string {
    const result = [];
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result.push(characters.charAt(Math.floor(Math.random() *
        charactersLength)));
    }
    return result.join('');
  }

  public createBlobImageFileFromBase64(base64TrimmedURL, fileType): File {
    const imageBlob: Blob = this.dataURItoBlob(base64TrimmedURL, fileType);
    const imageName: string = this.generateName(fileType);
    const imageFile: File = new File([imageBlob], imageName, {
      type: fileType
    });
    // window.URL.createObjectURL(imageFile);
    return imageFile;
  }

  public dataURItoBlob(dataURI: string, fileType: string): any {
    const byteCharacters = atob(dataURI);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], {type: fileType});
  }

  public generateName(fileType): string {
    const date: number = new Date().valueOf();
    let text = '';
    const possibleText = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    let ext = 'jpeg';
    if (fileType === 'image/png') {
      ext = 'png';
    }

    return date + '_' + text + '.' + ext;
  }
}
