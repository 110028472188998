import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'pyDate'
})
export class CustomDatePipe implements PipeTransform {
  transform(value: string, args?: any): any {
    return value.replace(' ', 'T');
  }
}
