// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
   apiBaseUrl: 'https://tasktrack-api.appsolutions.top/',
  version: 'v3.5.4',
  firebase: {
    apiKey: 'AIzaSyAzSNQIOaY7GKZWRQofU-mGHnYAmW7eqFo',
    authDomain: 'chekr-pro.firebaseapp.com',
    databaseURL: 'https://chekr-pro.firebaseio.com',
    projectId: 'chekr-pro',
    storageBucket: 'chekr-pro.appspot.com',
    messagingSenderId: '94774384935',
    appId: '1:94774384935:web:0786d1fbea47b7de233102'
  }
};
